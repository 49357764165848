import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Login from "./login";
import Register from "./register";
import Forgot from "./forgot";

import { Route, Switch } from "react-router";

export default function Auth(props: any) {
  const history = useHistory();

  const [viewState, setViewState] = useState({
    registration: false,
  });

  function changeView(registration: boolean) {
    setViewState({ ...viewState, registration });
  }

  if (props.authStore.user) {
    history.replace("/dashboard");
  }

  return (
    <main className="content">
      <div className="wrapper">
        <div className="content-container">
          <div className="landing-header landing-header-extended">
            {/* <div> instead of <form>, due to default event behaviour: */}
            <div className="landing-header-content login-form">
              <Route exact path="/auth">
                <Login authActions={props.authActions} />
              </Route>
              <Route exact path="/auth/register" component={Register} />
              <Route exact path="/auth/forgot" component={Forgot} />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
