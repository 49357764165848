import React, { useEffect, useState } from "react";
import { PostTransactionIntent, PostCheckoutCart, calculatePointsDifference } from "api/rpc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LocalStorage } from "api/localstorage";
import { StatusCode } from "api/protocols";
import {
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { CartNotification } from "elements/cart/cartNotifcation";

import "public/scss/cart.scss";

interface ICartInfo {
  id: number;
  cart_token: string;
  customer_id: number;
  customer_email: string;
  currency: string;
  total_discounts: number;
  total_line_item: number;
  subtotal_price: number;
  total_taxes: number;
  total_price: number;
  tax_lines?: JSON[]; //TODO: Tax object interface
  taxes_included: boolean;
  completed_at?: Date;
  closed_at?: Date;
  marketplace_order_id: number;
  notes: string;
  browser_details: JSON;
  customer_address_id: number;
  created_at?: Date;
  updated_at?: Date;
  line_items?: ICartItem[];
}

interface ICartItem {
  id: JSON;
  cart_id: number;
  product_id?: number;
  variant_id?: number;
  product_title: string;
  variant_title?: string;
  taxable: boolean;
  quantity: number;
  price: number;
  subtotal_price?: number;
  total_discount?: number;
  total_taxes?: number;
  total_price?: number;
  tax_lines?: JSON[]; //TODO: Tax object interface
  gift_card?: any;
  fulfillment_required: boolean;
  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date;
}

interface IAddOnItem {
  product_title: string;
  total_price: string;
}

export default function Cart(props: any) {
  const stripe = useStripe();

  // must be a better way to do this
  const [checkoutView, setCheckoutView] = useState<boolean>(true);

  const [insufficientFundsArray, setInsufficientFundArray] = useState<Array<any>>([]);

  useEffect(() => {
    console.log("Cart Token: ", props.cartToken);
    props.cartActions.fetchCart(props.cartToken);

    if (document.location.href.includes("order")) {
      setCheckoutView(true);
    } else if (document.location.href.includes("booking")) {
      setCheckoutView(false);
    }

    getPointsDifference();
  }, []);

  async function getPointsDifference() {
    const res = await calculatePointsDifference(props.cartToken);

    if (res.status !== StatusCode.OK) {
      return;
    }

    console.log(res);

    setInsufficientFundArray(res.data.data.line_items);
  }

  async function completeCart() {
    const cart_token = LocalStorage.get("cart_token");

    // Create a transaction intent
    const transactionIntent = await PostTransactionIntent(cart_token, true);

    console.info(transactionIntent.data.message);

    // Make sure there is a payment method
    if (props.checkoutStore.selectedPaymentMethod) {
      const paymentIntent = await stripe
        .confirmCardPayment(transactionIntent.data.data.payment_intent.client_secret, {
          payment_method: props.checkoutStore.selectedPaymentMethod.stripe_payment_method_id,
        })
        .then((result) => {
          // Handle result.paymentIntent
          if (result.paymentIntent) {
            console.log("payment intent complete");
            console.log(result);
            postCheckout(cart_token, result.paymentIntent.id, 0);
          } else if (result.error) {
            // Handle error
          }
        });
    }
  }

  async function postCheckout(cart_token: string, paymenIntentId: string, transactionId: number) {
    const checkoutRes = await PostCheckoutCart(cart_token, paymenIntentId, transactionId);
    if (checkoutRes.status !== 200) {
      // Handle some error message to the user
    }

    LocalStorage.remove("cart_token");
    props.history.push(`/order/${checkoutRes.data.data.order.id}`);
  }

  function updateQuantity(variant_id: number, current_quantity: number, line_item_id: any) {
    const quantity = current_quantity;
    const cart_token = LocalStorage.get("cart_token");
    if (quantity !== 0) {
      props.cartActions.updateCartLineItem(cart_token, quantity, variant_id);
    } else {
      props.cartActions.removeCartLineItem(cart_token, line_item_id);
    }
  }

  function convertPrice(price: number) {
    if (props.cartStore.currency === "cc") {
      return price.toFixed();
    }

    return price.toFixed(2);
  }

  return (
    <section className="cart-section">
      <h1>Cart</h1>
      <h3>Summary</h3>
      <p className="total-message">
        You have{" "}
        <span>
          {props.cartStore.line_item_count} item{props.cartStore.line_item_count != 1 && "s"}
        </span>{" "}
        in your cart
        {props.cartStore.line_item_count > 0 ? ":" : "."}
      </p>
      {props.cartStore.isLoaded && props.cartStore.line_item_count ? (
        <div className="cart-container">
          <ul className="cart-items-container">
            {props.cartStore.line_items.map((OItem: ICartItem, index: number) => {
              return (
                OItem.quantity !== 0 && (
                  <li className="cart-item" key={index}>
                    <div className="cart-item-content">
                      <p>
                        <span className="text-primary">{OItem.quantity} x</span>{" "}
                        <span className="product_title">
                          {OItem.product_title}{" "}
                          {OItem.product_title !== OItem.variant_title && (
                            <span className="variant_title">{OItem.variant_title}</span>
                          )}{" "}
                        </span>
                      </p>
                      <p className="dashed-line">.</p>
                      <p>
                        ${convertPrice(OItem.total_price)}
                        {props.cartStore.currency != "cc" ? (
                          <span className="currency">{props.cartStore.currency}</span>
                        ) : OItem.product_title.includes("Coins") ? (
                          <FontAwesomeIcon icon={["fas", "coins"]} />
                        ) : OItem.product_title === "Power Cart" ? (
                          <FontAwesomeIcon icon={["fal", "car-side"]} />
                        ) : (
                          <FontAwesomeIcon icon={["fal", "golf-ball"]} />
                        )}
                      </p>
                    </div>
                    {props.allowQuantityChange ? (
                      <div className="update-buttons-container">
                        <button
                          className="update-button"
                          onClick={() => updateQuantity(OItem.variant_id, OItem.quantity - 1, OItem.id)}
                        >
                          <FontAwesomeIcon
                            title="Decrease quantity"
                            aria-label="Decrease quantity"
                            icon={["fal", "minus-circle"]}
                          />
                        </button>
                        <button
                          className="update-button"
                          onClick={() => updateQuantity(OItem.variant_id, OItem.quantity + 1, OItem.id)}
                        >
                          <FontAwesomeIcon
                            title="Increase quantity"
                            aria-label="Increase quantity"
                            icon={["fal", "plus-circle"]}
                          />
                        </button>
                      </div>
                    ) : null}
                  </li>
                )
              );
            })}
          </ul>
          <div className="cart-pricing">
            <table>
              {document.location.href.includes("checkout") ? (
                <tbody>
                  <tr>
                    <td className="text-right text-subdued">Subtotal</td>
                    <td className="text-right text-subdued">
                      <span>
                        ${convertPrice(props.cartStore.subtotal_price)}
                        {props.cartStore.currency != "cc" ? (
                          <span className="currency">{props.cartStore.currency}</span>
                        ) : (
                          !checkoutView && (
                            <FontAwesomeIcon icon={["fas", "coins"]} className="text-primary currency-coins" />
                          )
                        )}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-right text-subdued">HST</td>
                    <td className="text-right text-subdued">
                      <span>
                        ${convertPrice(props.cartStore.total_taxes)}
                        {props.cartStore.currency != "cc" ? (
                          <span className="currency">{props.cartStore.currency}</span>
                        ) : (
                          !checkoutView && (
                            <FontAwesomeIcon icon={["fas", "coins"]} className="text-primary currency-coins" />
                          )
                        )}
                      </span>
                    </td>
                  </tr>
                </tbody>
              ) : null}

              {props.displayWalletBalance ? (
                <tbody>
                  <tr>
                    <td className="text-right text-subdued">Wallet Balance</td>
                    <td className="text-right text-subdued">
                      <span>{props.authStore.user.points.balance}</span>
                      <FontAwesomeIcon icon={["far", "coins"]} className="text-subdued currency-coins" />
                    </td>
                  </tr>
                </tbody>
              ) : null}
              <tbody>
                <tr>
                  <td className="text-right">Total</td>
                  <td className="text-right">
                    <span>
                      {props.cartStore.currency == "cc" && checkoutView && "$"}
                      {convertPrice(props.cartStore.total_price)}
                      {props.cartStore.currency != "cc" ? (
                        <span className="currency">{props.cartStore.currency}</span>
                      ) : (
                        !checkoutView && (
                          <FontAwesomeIcon icon={["fas", "coins"]} className="text-primary currency-coins" />
                        )
                      )}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* Need better check here */}
          {!checkoutView ? (
            <>
              {props.authStore.user.points.balance < props.cartStore.total_price ? (
                <div>
                  <CartNotification
                    icon={["fas", "exclamation-triangle"]}
                    iconType={"icon-danger"}
                    content={"Insufficient coin balance! Purchase more coins to complete transaction."}
                  />
                  {/* This will be calculated on the back end with an endpoint */}
                  <ul className="cart-items-container">
                    {insufficientFundsArray.map((item: IAddOnItem, index: number) => {
                      return (
                        <li className="cart-item" key={index}>
                          <div className="cart-item-content">
                            <p>
                              <span className="product_title">+{item.product_title}</span>
                            </p>
                            <p className="dashed-line">.</p>
                            <p>${item.total_price}</p>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : (
                !checkoutView && <CartNotification content={"Total will be deducted from your wallet balance"} />
              )}
            </>
          ) : null}
        </div>
      ) : null}
      {/* <button onClick={completeCart}>Complete Cart</button> */}
    </section>
  );
}
