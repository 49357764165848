import React from "react";
import classNames from "classnames";
import "./style.scss";

interface IProps {
  prefixCls?: string;
  className?: string;
  message: string;
  type?: "success" | "info" | "warning" | "error";
  style?: any;
}

export const Alert: React.FC<IProps> = (props: any) => {
  const { prefixCls = "rc-alert", className, message, type = "warning", showIcon, ...rest } = props;

  const alertClassName = classNames(
    prefixCls,
    { [`${prefixCls}-${type}`]: type },
    { [`${prefixCls}-${type}-icon`]: showIcon },
    className,
  );

  return (
    <div className={alertClassName} {...rest}>
      <span>{message}</span>
    </div>
  );
};

export default Alert;
