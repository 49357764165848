import React, { useEffect, useState } from "react";
import { BrowserRouter, useHistory, Link } from "react-router-dom";

import 'public/scss/landing.scss';


export default function Privacy(props: any) {

  return (
      <main>
        <div className="wrapper">
          <div className="content-container">
              <div className="landing-header">
                <div className="landing-header-content">
                    <h1>Privacy Policy</h1>

                    <h3>Clubhouse Golf Values Your Privacy</h3>
                    <p>Clubhouse is committed to protecting the privacy, confidentiality, accuracy and security of your personal information (see the definition of “Personal Information” below).  Whether we collect, use, retain or disclose your personal information in the course of conducting business, you can rest assured that we will only do so in accordance with our Privacy Policy.</p>

                    <h3>Personal Information Collected by Clubhouse</h3>
                    <p>In building a lasting relationship with you, and to provide you with the valued products and services that you desire, we require specific personal information.  Such information varies depending on the products or services purchased or utilized by you. </p>

                    <h3>Why Clubhouse Collects Your Personal Information</h3>
                    <p>During the course of our relationship with you, we may collect, use and disclose your personal information for the following reasons:  to identify you, to confirm your email address or other purchase information; to provide you with ongoing products and services, establish and maintain communication, and respond to your inquiries; to offer you additional products and services; to provide you with information that we believe you may find of interest; to promote products and services to you from business partners with whom we have an association; or to meet legal and statutory requirements.</p>
                    <p>You can take comfort in knowing that our use of your personal information is always in a manner consistent with applicable laws.  Should we need to use your personal information for purposes other than those already listed, we will explain the purpose to you and obtain your consent – before we use it.  Please note that we do not collect, use or disclose your personal information unless we have your consent or unless it is done so in accordance with our Privacy Policy.  And, we do not sell your personal information.</p>
                    <p>Only authorized personnel have access to your information and our systems and procedures are designed to prevent the loss, misuse, unauthorized access, disclosure, alteration, or destruction of your personal information.  We retain your information only as long as is required for the purposes for which it was collected and to meet any legal requirements.</p>

                    <h3>Your Rights Regarding Your Personal Information</h3>
                    <p>As a valued Clubhouse client, you have the right to access your personal information and request changes at any time.  It is your legal right to choose not to provide us with some or all of your personal information, or to deny us the use or disclosure of your information for certain purposes.  Should you exercise this right, we will not unreasonably withhold any requested products or services.  However, certain basic information must be provided to supply most requested products or services.</p>
                    <p>By providing your consent for us to collect, use and disclose your personal information, we are better able to provide you with personalized products and services.  This may include, but is not limited to, special product and service offerings, contests, league events, new discounts, and promotions.</p>

                    <h3>Limiting the Use of Your Personal Information</h3>
                    <p>If you would prefer not to receive further service and product information and instead are content to limit your personal information use to the current Clubhouse product and service offerings utilized by you, please let us know by sending us an email.  However, if you wish to be able to receive service and product information, simply do nothing and we will be able to use your personal information to share ideas about Clubhouse products and services that we feel may be of benefit to you.</p>

                    <h3>What “Personal Information” Means in Our Policy</h3>
                    <p>We define personal information as information – oral, electronic, or written – about an identifiable individual, including, but not limited to:  name, address, telephone number, age, gender, photograph, basic employment information, identification numbers and credit or banking information.  Personal information does not include an individuals business title or business contact information. Some of the personal information that we may collect while conducting business includes demographic and financial information, such as credit card information and personal address information. Personal information as set out above is typically collected through on-line purchase forms and various documents that players provide to us from time to time.  In some instances, this includes information that customers provide through the Clubhouse league software or tee-time booking engine.</p>

                    <h3>Our Policy on the Protection of Personal Information</h3>
                    <p>We will not sell your personal information to third parties. We will keep all personal information that you provide to us secure from accidental disclosure.  We will only gather and keep the information necessary to provide you with proper products and services.  We will provide you with the opportunity to review any personal information we have on file about you and to make changes if you discover an error.</p>

                    <h3>Your Consent</h3>
                    <p>Before we collect any personal, private information from you, we will get your consent. Consent can be either express or implied.  In matters relating to particularly sensitive information such as financial information, we would require you to provide us with express consent. Express consent can be given either in writing or orally. For example, when you make an on-line purchase and provide us with your credit card information, we require your signature as your consent to allow us to use your credit card information for payment purposes.  In other matters, your consent may be implied. </p>

                    <h3>Disclosure and Retention of Your Personal Information</h3>
                    <p>We will never sell or otherwise disclose your personal information to any outside agency or organization for commercial purposes without your consent.  From time to time, certain organizations may present an opportunity for our customers that we may determine to be of interest to you and we will pass this information on to you.  However, we will never pass on your personal information to someone else without first getting your consent.  Due to the risk of inadvertent disclosure of your personal information to those not authorized to receive it, we will limit disclosure of information pertaining to your account.   Unless you identify an individual that may make inquiries on your behalf, we will not disclose any information about you or your account with us to anyone except you or anyone named by you.</p>
                    <p>There may be certain situations where we may disclose your personal information without your consent.  These situations include:  Where we believe that we are required to by law; Internally, where we are required to fulfill a contract or to provide you with the services you have contracted for; Where we feel we need to, to protect our rights or our property; or Where we have first acquired your consent. We may use your personal information on an aggregated basis for disclosure to third parties. For example, to provide a third party with a demographic statistic such as 13% of Clubhouse customers are between the ages of 35 - 45. However, be assured that you will never be personally identified.  We are subject to certain legislative requirements regarding the retention of certain records for a period of time.  </p>
                    <p>As long as you are an active Clubhouse customer, we will maintain a file containing the information necessary to service your needs.  We will update your file as you provide us with new information.  Should you ever cease to be a Clubhouse customer, your personal information will be kept only for a reasonable period thereafter. When your information is no longer needed, it is destroyed, or it is made anonymous.</p>


                    <h3>How We Protect Your Personal Information</h3>
                    <p>All personal information we have on file is held in a secure location, whether locked in cabinets or password protected on our network.  No one without proper authorization has access to your information.  As it relates to our web site, our use of industry-standard firewalls are a combination of hardware and software that separately secures the Internet from our web servers and our web servers from our data. They are designed to prevent unauthorized access to our web servers or our data. </p>

                    <h3>Accuracy and Transparency of Personal Information</h3>
                    <p>We will maintain as accurate a file as possible and will update your personal information as you provide it to us. At any time, you may make a request to see the information that we have on file about you. If you believe that we have inaccurate information on file, you may request that we update our records. Likewise, you may request that we destroy any outdated information.</p>

                    <h3>Contacting Clubhouse’s Privacy Officer</h3>
                    <p>
                    Please direct your privacy inquiries to:

                        Clubhouse Privacy Officer
                        www.clubhousegolf.ca</p>


                </div>
            </div>
          </div>
        </div>
      </main>
  );
}
