import { connect } from "react-redux";
import Order from "components/order";
import { bindActionCreators } from "redux";
import * as OrderActions from "actions/order";

const mapStateToProps = (state: any) => {
  return {
    checkoutStore: { ...state.checkoutStore },
    authStore: { ...state.authStore },
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    checkoutActions: bindActionCreators(
      {
        ...OrderActions,
      },
      dispatch,
    ),
  };
};

export default connect<any>(mapStateToProps, mapDispatchToProps)(Order);
