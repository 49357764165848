export interface ITeetimeAction {
  type: string;
  payload: any;
}

export interface ITeetimeState {
  date: string;
  time: { min: number; max: number };
  points: { min: number; max: number };
  holes: number;
  players: number;
  cart: boolean;
}

const initialState: ITeetimeState = {
  date: new Date().toLocaleDateString(),
  time: { min: 6, max: 20 },
  points: { min: 175, max: 375 },
  holes: 18,
  players: 2,
  cart: false,
};

export default function TeetimeReducer(state = initialState, action: ITeetimeAction) {
  switch (action.type) {
    case "update.filter":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
