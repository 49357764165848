import React from "react";
import styled from "styled-components";

export interface ILoaderProps {
  uiStore: any;
}

const StyledContainer = styled.div`
  z-index: 999;
  background: white;
  width: 100%;
  height: 100%;
  position: fixed;
`;

const StyledLoader = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  text-align: center;
`;

export default function Loader(props: ILoaderProps) {
  if (!props.uiStore.loading) {
    return null;
  }
  return (
    <StyledContainer>
      <StyledLoader>
        <img src={"/public/images/loader.svg"} />
        {/* TODO: show message(s) on what is being loaded, or other more useful user-/debug-friendly info */}
        <div>Loading...</div>
      </StyledLoader>
    </StyledContainer>
  );
}
