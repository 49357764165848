import { DeleteCartLineItem, GetCart, PostCartLineItem, PostCartNew } from "api/rpc";
import { LocalStorage } from "api/localstorage";
import { ICartState } from "reducers/cart";
import { StatusCode } from "api/protocols";

export function fetchCart(cartToken: string) {
  return async (dispatch: any) => {
    const res = await GetCart(cartToken);
    if (res.status !== StatusCode.OK) {
      //TODO: handle error here
      console.log(res);
      return;
    }

    if (!res.data.data) {
      const res = await PostCartNew(true);
      if (res.status !== StatusCode.OK) {
        //TODO: handle error here
        console.log(res);
        return;
      }

      LocalStorage.set("cart_token", res.data.data.cart_token);
    }

    if (res.data.data.cart_token !== LocalStorage.set("cart_token", res.data.data.cart_token)) {
      LocalStorage.set("cart_token", res.data.data.cart_token);
    }

    dispatch({
      type: "update.cart",
      payload: {
        isLoaded: true,
        cart_token: res.data.data.cart_token,
        currency: res.data.data.currency,
        line_items: res.data.data.line_items,
        line_item_count: res.data.data.line_item_count,
        subtotal_price: res.data.data.subtotal_price,
        tax_lines: res.data.data.tax_lines,
        total_taxes: res.data.data.total_taxes,
        total_price: res.data.data.total_price,
      },
    });
  };
}

export function fetchBookingCart(cartToken: string) {
  return async (dispatch: any) => {
    const res = await GetCart(cartToken);
    if (res.status !== StatusCode.OK) {
      //TODO: handle error here
      console.log(res);
      return;
    }

    dispatch({
      type: "update.cart",
      payload: {
        isLoaded: true,
        cart_token: res.data.data.cart_token,
        currency: res.data.data.currency,
        line_items: res.data.data.line_items,
        line_item_count: res.data.data.line_item_count,
        subtotal_price: res.data.data.subtotal_price,
        tax_lines: res.data.data.tax_lines,
        total_taxes: res.data.data.total_taxes,
        total_price: res.data.data.total_price,
      },
    });
  };
}

export function updateCartLineItem(cartToken: string, quantity: number, variantID: number, increaseQty?: boolean) {
  return async (dispatch: any) => {
    const res = await PostCartLineItem(cartToken, quantity, variantID, increaseQty);
    if (res.status !== StatusCode.OK) {
      //TODO: handle error here
      console.log(res);
      return;
    }
    dispatch({
      type: "update.cart",
      payload: {
        isLoaded: true,
        line_items: res.data.data.line_items,
        line_item_count: res.data.data.line_item_count,
        subtotal_price: res.data.data.subtotal_price,
        tax_lines: res.data.data.tax_lines,
        total_taxes: res.data.data.total_taxes,
        total_price: res.data.data.total_price,
      },
    });
  };
}

export function removeCartLineItem(token: string, lineitemID: number) {
  return async (dispatch: any) => {
    const res = await DeleteCartLineItem(token, lineitemID);
    if (res.status !== StatusCode.OK) {
      //TODO: handle error here
      console.log(res);
      return;
    }
    dispatch({
      type: "update.cart",
      payload: {
        isLoaded: true,
        line_items: res.data.data.line_items,
        line_item_count: res.data.data.line_item_count,
        subtotal_price: res.data.data.subtotal_price,
        tax_lines: res.data.data.tax_lines,
        total_taxes: res.data.data.total_taxes,
        total_price: res.data.data.total_price,
      },
    });
  };
}

export function updateCart(newCartState: ICartState) {
  return (dispatch: any) => {
    dispatch({
      type: "update.cart",
      payload: newCartState,
    });
  };
}
