interface ICartLineItem {
  id: JSON;
  cart_id: number;
  product_id?: number;
  variant_id?: number;
  product_title: string;
  variant_title?: string;
  taxable: boolean;
  quantity: number;
  price: number;
  subtotal_price?: number;
  total_discount?: number;
  total_taxes?: number;
  total_price?: number;
  tax_lines?: JSON[]; //TODO: Tax object interface
  line_items?: JSON[]; //TODO: Tax object interface
  gift_card?: any;
  fulfillment_required: boolean;
  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date;
}

export interface ICartState {
  isLoaded: boolean;
  error?: any;
  cart_token: string;
  line_items: ICartLineItem[];
  line_item_count: number;
}

export interface ICartAction {
  type: string;
  payload: any;
}

const initialState: ICartState = {
  isLoaded: false,
  error: null,
  cart_token: null,
  line_items: [],
  line_item_count: 0,
};

export default function CartReducer(state = initialState, action: ICartAction) {
  switch (action.type) {
    case "update.cart":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
