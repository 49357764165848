import { IUser } from "./models/generals";

export interface IDashboardState {
  user: undefined | IUser;
}

const initialState: IDashboardState = {
  user: undefined,
};

export interface IDashboardAction {
  type: string;
  payload: IUser;
}

export default function DashboardReducer(state = initialState, action: IDashboardAction) {
  switch (action.type) {
    case "":
      return state;
    default:
      return state;
  }
}
