import React, { useState, createContext, useContext } from "react";
import classNames from "classnames";
import "./index.scss";

interface ICardProps {
  prefixCls?: string;
  className?: string;
  overlay?: any;
  key?: any;
  value?: any;
}

interface IGroupContext {
  value: string | number;
  setValue: (value: string | number) => void;
}

interface IGroupProps {
  prefixCls?: string;
  className?: string;
}

interface ICardComposition {
  Group: React.FC<IGroupProps>;
}

export const CardGroupContext = createContext<IGroupContext | undefined>(undefined);

const Group: React.FC<IGroupProps> = (props: any) => {
  const { prefixCls = "rc-card-group", className, children, ...rest } = props;

  const [value, setValue] = useState<any | undefined>(null);

  return <CardGroupContext.Provider value={{ value, setValue }}>{children}</CardGroupContext.Provider>;
};

export const Card: React.FC<ICardProps> & ICardComposition = (props: any) => {
  const { prefixCls = "rc-card", className, overlay, children, value, ...rest } = props;

  const [expand, setExpand] = useState(false);

  const cardStore = useContext(CardGroupContext);

  const selectCard = () => {
    if (cardStore) {
      cardStore.setValue(value);
    } else {
      setExpand(true);
    }
  };

  const cardClassName = classNames(prefixCls, className);

  const overlayNode = overlay || null;

  return (
    <div className={cardClassName} onClick={selectCard} {...rest}>
      {children}
      {(cardStore.value === value || expand) && overlayNode}
    </div>
  );
};

Card.Group = Group;

export default Card;
