import React, { useState, useRef, useEffect, cloneElement } from "react";
import useOutsideAlerter from "hooks/useOutsideAlerter/useOutsideAlerter";
import classNames from "classnames";
import "./style.css";

export interface DropDownProps {
  trigger?: ("click" | "hover" | "contextMenu" | string)[];
  overlay: any;
  disabled?: boolean;
  className?: string;
}

export const Dropdown: React.FC<DropDownProps> = (props) => {
  const { className, children, overlay, disabled, trigger } = props;

  const [visible, setVisible] = useState(false);

  const [offset, setOffset] = useState();

  // onkeydown selection
  const [selection, setSelection] = useState();

  const ref = useRef(null);

  useEffect(() => {
    let mounted = true;

    if (ref.current) {
      const clientHeight = ref.current.children[0].clientHeight;
      const offset = clientHeight + 10;
      if (mounted) {
        setOffset(offset);
      }
    }

    return () => {
      mounted = false;
    };
  }, []);

  // ensures only one child or throws an error
  const child = React.Children.only(children) as React.ReactElement<any>;

  const dropdownTrigger = cloneElement(child);

  useEffect(() => {
    let mounted = true;

    if (trigger[0].length >= 1 && visible === false) {
      if (mounted) {
        setVisible(true);
      }
    }

    if (trigger[0].length === 0 && visible === true) {
      if (mounted) {
        setVisible(false);
      }
    }

    return () => {
      mounted = false;
    };
  }, [trigger]);

  const dropdownClassName = classNames("rc-dropdown");

  const overlayContainerClassName = classNames(
    "overlay-container",
    { "overlay-container-visible": visible },
    className,
  );

  useOutsideAlerter(ref, () => setVisible(false));

  return (
    <div className={dropdownClassName} ref={ref}>
      {dropdownTrigger}
      {visible && (
        <div className={overlayContainerClassName} style={{ top: offset }}>
          {overlay}
        </div>
      )}
    </div>
  );
};
