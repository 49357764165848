import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Moment from "moment";
import "public/scss/booking.scss";

export function BookingDetails(props: any) {
  return (
    <section className="booking-section">
      <h1>Booking Details</h1>
      <div className="booking-card">
        <h4>{props.long_name}</h4>
        <p className="booking-date">
          <FontAwesomeIcon icon={["far", "calendar"]} className="text-primary" />
          {Moment(props.start_time).format("dddd, MMMM Do YYYY")} at{" "}
          <span className="text-primary text-bold text-underline"> {Moment(props.start_time).format("h:mm a")}</span>
        </p>
        <div className="booking-totals-container">
          <p>
            <FontAwesomeIcon icon={["far", "users"]} className="text-primary" />
            {props.quantity} Golfer{props.quantity !== 1 && "s"}
          </p>
          <p>
            <FontAwesomeIcon icon={["far", "flag"]} className="text-primary" />
            {props.holes_booked} Holes
          </p>
          {props.power_cart_quantity > 0 && (
            <p>
              <FontAwesomeIcon icon={["fal", "car-side"]} className="text-primary" />
              {props.power_cart_quantity} Cart{props.power_cart_quantity !== 1 && "s"}
            </p>
          )}
        </div>
      </div>
    </section>
  );
}
