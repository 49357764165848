import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as authActions from "actions/auth";
import * as teetimeActions from "actions/teetime";
import * as bookingActions from "actions/booking";
import Facility from "components/teetime/facility";

const mapStateToProps = (state: any) => {
  const { authStore } = state;
  return {
    authStore: { ...authStore },
    teetimeStore: { ...state.teetimeStore },
    bookingStore: { ...state.bookingStore },
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    authActions: bindActionCreators(
      {
        ...authActions,
      },
      dispatch,
    ),
    teetimeActions: bindActionCreators(
      {
        ...teetimeActions,
      },
      dispatch,
    ),
    bookingActions: bindActionCreators(
      {
        ...bookingActions,
      },
      dispatch,
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Facility);
