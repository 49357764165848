import React, { useState } from "react";
import { FetchCSRFCookie, UserLogin, UserRegister, UserActive } from "api/rpc";
import { useHistory, Link } from "react-router-dom";
import { InputField } from "elements/inputField";
import { StatusCode } from "api/protocols";
import { Title, SubTitle } from "elements/typography/index";
import { Button } from "elements/button/index";
import { Input } from "elements/input/Input";
import "public/scss/auth.scss";

interface ILoginProps {
  authActions: any;
  // changeView: (registration: boolean) => void;
}

export default function Login(props: ILoginProps) {
  const history = useHistory();

  const [state, setState] = useState({
    email: "",
    password: "",
    status: "",
  });

  async function handleLogin() {
    //TODO: handle these via the UI before sending:
    if (state.email == "" || state.password == "") {
      // covers all falsey cases? (null, undefined, ...)
      setState({ ...state, status: "email/password empty" });
      return;
    }
    const fetchXSRFTokenRes = await FetchCSRFCookie(true);

    if (fetchXSRFTokenRes.status !== 204) {
      setState({ ...state, status: "Login error.. Fetch XSRF token failed" });
      return;
    }

    const loginRes = await UserLogin(state.email, state.password, true);

    if (loginRes.status !== StatusCode.OK) {
      setState({ ...state, status: `${loginRes.data.error}` });
      return;
    }

    const activeUserRes = await UserActive(true);

    if (activeUserRes.status === StatusCode.OK) {
      props.authActions.userLogin(activeUserRes.data.data);
      history.push("/");
    } else {
      setState({ ...state, status: `${activeUserRes.data.error}` });
    }
  }

  function handleChange(event: any) {
    const { id, value } = event.target;
    setState((prevState) => ({ ...prevState, [id]: value }));
  }

  const handleEmailChange = (value: any) => {
    const email = value;

    setState({
      ...state,
      email,
    });
  };

  const handlePasswordChange = (value: any) => {
    const password = value;
    setState({
      ...state,
      password,
    });
  };

  const canSubmit = state.email.length >= 1 && state.password.length >= 1;

  return (
    <div className="ui-login-group">
      <img className="ui-login-logo" src="/public/images/clubhouse_logo_full.png" />

      <Title level={3} type={2}>
        Login
      </Title>

      <Input type="email" placeholder="Email" onChange={handleEmailChange} />

      <Input type="password" placeholder="Password" onChange={handlePasswordChange} />

      <div>{state.status}</div>

      <Button
        shape="rounded"
        size="large"
        block
        type="primary"
        onClick={handleLogin}
        disabled={!canSubmit}
        className="ui-login-button"
      >
        Login
      </Button>

      <div className="ui-login-secondary-buttons">
        <Link to="/auth/forgot">Forgot Password?</Link>

        <Link to="/auth/register">Create New Account</Link>
      </div>
    </div>
  );
}
