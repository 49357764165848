import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import "public/scss/filters.scss";

import classNames from "classnames";

interface IProps {
  id: string;
  title: string;
  options: {}[];
  selected: any;
  onChange?: (value: any) => void;
}

export const FilterRadio: React.FC<IProps> = (props: any) => {
  const { id, title, options, selected, onChange } = props;

  const [value, setValue] = useState();

  const handleChange = (value: any) => {
    setValue(value);
  };

  useEffect(() => {
    let mounted = true;

    if (onChange) {
      if (mounted) {
        onChange(value);
      }
    }

    return () => {
      mounted = false;
    };
  }, [value]);

  return (
    <div className="filter">
      <div className="filter-title">
        <p>{title}</p>
      </div>
      <div className="filter-radio-button-container filter-radio-button-horiztonal">
        {options.map((option: any, i: number) => {
          const filterRadioClassName = classNames(
            "filter-radio-button",
            { selected: selected === option.value },
            { disabled: option.disabled },
          );

          return (
            <div key={i} className={filterRadioClassName} onClick={() => handleChange(option.value)}>
              <div className="filter-radio-button-content">
                <span>{option.name}</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
