import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as authActions from "actions/auth";
import * as CartActions from "actions/cart";
import BuyCoins from "components/buyCoins";

const mapStateToProps = (state: any) => {
  const { authStore } = state;
  return {
    authStore: { ...authStore },
    cartStore: { ...state.cartStore },
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    authActions: bindActionCreators(
      {
        ...authActions,
      },
      dispatch,
    ),
    cartActions: bindActionCreators(
      {
        ...CartActions,
      },
      dispatch,
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BuyCoins);
