import React from "react";
import classNames from "classnames";
import "./style.scss";

interface IProps {
  className?: string;
  prefixCls?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

export const Divider: React.FC<IProps> = (props) => {
  const { prefixCls = "rc-divider", className, children, ...rest } = props;

  const separatorClassName = classNames(prefixCls, className);

  return (
    <div className={separatorClassName} role="separator" {...rest}>
      {children && <span className={`${prefixCls}-inner-text`}>{children}</span>}
    </div>
  );
};
