import { combineReducers } from "redux";
import AuthReducer from "./auth";
import DashboardReducer from "./dashboard";
import UIReducer from "./ui";
import CheckoutReducer from "./checkout";
import CartReducer from "./cart";
import TeetimeReducer from "./teetime";
import BookingReducer from "./booking";
import LandingReducer from "./landing";

export default combineReducers({
  authStore: AuthReducer,
  dashboardStore: DashboardReducer,
  uiStore: UIReducer,
  checkoutStore: CheckoutReducer,
  cartStore: CartReducer,
  teetimeStore: TeetimeReducer,
  bookingStore: BookingReducer,
  landingStore: LandingReducer,
});
