import React from "react";

export function InputField({ children, ...rest }: any) {
  return (
    <div className="form-grid">
      <div className="form-cell">
        <div className="form-wrapper">
          <input className="input-large" {...rest} />
        </div>
      </div>
    </div>
  );
}
