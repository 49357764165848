import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-dates/lib/css/_datepicker.css";
import DatePicker from "react-date-picker";
import "public/scss/dateSelector.scss";
interface IProps {
  onChange?: (value: any) => void;
}

export const DateSelector: React.FC<IProps> = props => {
  const { onChange } = props;

  const [value, setValue] = useState<any>(new Date());

  //placeholder not working
  //docs https://www.npmjs.com/package/react-date-picker

  const handleChange = (value: any) => {
    setValue(value);
  };

  useEffect(() => {
    let mounted = true;

    if (onChange) {
      if (mounted) {
        onChange(value);
      }
    }

    return () => {
      mounted = false;
    };
  }, [value]);

  return (
    <div>
      <DatePicker
        onChange={handleChange}
        value={value}
        clearIcon={null}
        dayPlaceholder="Today"
        calendarIcon={<FontAwesomeIcon icon={["fal", "calendar"]} />}
      />
    </div>
  );
};
