import { IUser } from "reducers/models/generals";

export function userLogin(user: IUser) {
  return (dispatch: any) => {
    dispatch({ type: "user.login", payload: user });
  };
}

export function userLogout() {
  return (dispatch: any) => {
    dispatch({ type: "user.logout" });
  };
}

export const refreshUser = (forceRefresh = false) => {
  return (dispatch: any) => {
    dispatch({
      type: "REFRESH_USER",
      payload: forceRefresh,
    });
  };
};
