import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


import 'public/scss/landing.scss';

export default function EmailSignUp(props: any) {
  const AttnString: string[] = [
    `Early access to the hottest deals.`,
    `Never miss a deal.`,
  ];

  return (
    <div className="landing-section-email landing-section">
      <div className="wrapper">
        <h5 className="section-pre-header section-pre-header-center">Clubhouse Golf Exclusives</h5>
        <h2 className="section-title-center">
          { AttnString[ Math.floor(Math.random() * AttnString.length )]}
        </h2>
        <p className="section-description section-description-center">Sign up for our mailing list and gain instant access to the biggest savings we have to offer. Stay in the know and receive new deals before they reach the public.</p>
        <div className="section-spacer"></div>
        <div className="form-grid form-grid-75">
          <div className="form-cell">
            <div className="form-wrapper">
              <input className="input-large"
                placeholder="Enter your email address"
                type="text"/>
                <button className="button-primary"><span className="desktop-search">Sign Up</span><span className="mobile-search"><FontAwesomeIcon icon={['fal', 'search']} /></span></button>
            </div>
          </div>
        </div>
      </div>  
    </div>


  );
}
