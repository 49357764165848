export interface IUIState {
  queue: number;
  loading: boolean;
  showError: boolean;
  errorMessage: string;
}

export interface IUIAction {
  type: string;
  payload: any;
}

const initialState: IUIState = {
  queue: 0,
  loading: false,
  showError: false,
  errorMessage: "",
};

export default function UIReducer(state = initialState, action: IUIAction) {
  switch (action.type) {
    case "loading":
      return {
        ...state,
        loading: action.payload,
      };
    case "modify.queue":
      return {
        ...state,
        queue: action.payload,
      };
    case "error.show":
      return {
        ...state,
        showError: true,
        errorMessage: action.payload.errorMessage,
      };
    case "error.close":
      return {
        ...state,
        showError: false,
        errorMessage: "",
      };
    default:
      return state;
  }
}
