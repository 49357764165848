import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EmailSignUp from "./emailSignUp";
import Facilities from "components/teetime/facilities";

import { Menu } from "elements/menu";
import { Dropdown } from "elements/dropdown/dropdown";
import Input from "elements/input/Input";
import { Button } from "elements/button/index";
import { Title, SubTitle } from "elements/typography/index";
import { Rate } from 'elements/rate/index';
import { Divider } from 'elements/divider/index';

import { clubhouse } from '../../api/rpc';

import 'public/scss/landing.scss';


// TODO: interface useful for course details sitewide?
interface ICourseDetails {
  name: string;
  address: string;
  rating: number; //ideally, max 1 digit after decimal point; valid range: 0 to 5
  pts: [number,number]; //ideally unsigned int
  thumbnail: string;
}


export default function Landing(props: any) {

  const { retrieveQuery } = props.landingActions;

  const [query, setQuery] = useState("");

  const [results, setResults] = useState([]);

  const handleInputChange = (value: any) => {
    setQuery(value)
  }


  useEffect(() => {
    let mounted = true;
    let timeoutId: any = null;

    const searchClubHouse = () => {

      // debounce
      clearTimeout(timeoutId);
      timeoutId = setTimeout(async () => {
        try {
          const res = await clubhouse.facilities.query(query);

          const filteredRes: object[] = [];

          if (res !== undefined) {
            res.data.data.forEach((result: any) => { 
          
            const firstCharQuery = query.charAt(0).toLowerCase();
            const firstCharResult = result.long_name.charAt(0).toLowerCase();

            if (firstCharQuery === firstCharResult) {
              filteredRes.push(result);
            } else {
              return;
            }
            })

            if (mounted) {
              setResults(filteredRes);
            }
          }

        } catch (error) {
          console.log(error)
        }
      }, 50);
    }

    searchClubHouse();

    // to prevent empty drop down from appearing with no results
    if (query.length === 0) {
      if (mounted) {
        setResults([]);
      }
    }

    return () => {
      mounted = false;
    }

  },[query])

  const menu = (
        results.length >= 1 ?
        <Menu>
          {
            results.map((result, i) => {
              const facility_id = result.id;

              const firstCharQuery = query.charAt(0).toLowerCase()
              const firstCharResult = result.long_name.charAt(0).toLowerCase();

              const rating = result.default_rating / 20;

              if (firstCharQuery === firstCharResult) {
                return (
                  <React.Fragment key={i}>
                    <Menu.Item>
                      <Link to={`/facility/${facility_id}`}>
                        <div className="landing-search-result">
                          <img className="landing-search-result-image" src={result.default_image.source} />
                          <div className="landing-search-result-details">
                            <Title level={2}>{result.long_name}</Title>
                            <SubTitle>{result.address_line_1}, {result.city} {result.province_code}</SubTitle>
                            <Rate value={rating} />
                          </div>
                        </div>
                      </Link>
                    </Menu.Item>
                    {results.length > 1 && <Divider className="landing-search-result-divider" />}
                  </React.Fragment>
                )
              }
            })
          }
        </Menu> : null
  );


  const arOExampleCourses: Array<ICourseDetails> = [
    {
      name: `Burford Golf Links`,
      address: `120 Golf Links Road, Burford ON`,
      rating: 5,
      pts: [60,110],
      thumbnail: 'burford_hole8.jpg',
    },
    {
      name: `Grey Silo Golf Club`,
      address: `2001 University Ave. E, Waterloo ON`,
      rating: 3.4,
      pts: [90,140],
      thumbnail: 'greysilo_hole3_thumb.jpg',
    },
    {
      name: `Rebel Creek Golf Club`,
      address: `1517 Snyder's Road, Petersburg ON`,
      rating: 4.1,
      pts: [120,160],
      thumbnail: 'rebel_creek_arial.jpg',
    },
  ];

  const jsxExampleCourses = arOExampleCourses.map( (OCourse,index) =>
  (
    <div className="course-card" key={index}>
        <div className="course-card-content">

            <div className="card-image">
                <img src={ `/public/images/thumbs/${OCourse.thumbnail}` }
                    alt={OCourse.name + ' photo'}
                />
            </div>

            <div className="course-details">
                <h3>{ OCourse.name }</h3>
                <p>{ OCourse.address }</p>

                <div className="star-rating" title={OCourse.rating + ' Stars'}>
                  {(() => { //use an IIFE to do complex ops, then display the result
                    const rating = OCourse.rating;
                    let count = 1;
                    const arJSXRating = [];

                    //Solid stars:
                    for (; count<=rating && count<=5; count++) {
                      arJSXRating.push( <FontAwesomeIcon key={count} icon={['fas','star']} /> );
                    }
                    //Partial stars:
                    if (rating % 1 > 0.2) {
                      arJSXRating.push( <FontAwesomeIcon key={count} icon={['fas','star-half-alt']} /> );
                      count++;
                    }
                    //Empty stars:
                    while (count<=5) {
                      arJSXRating.push( <FontAwesomeIcon key={count} icon={['far','star']} /> );
                      count++;
                    }

                    return arJSXRating;
                  })()}
                </div>
            </div>

        </div>
    </div>
  ));

  const searchIcon = <FontAwesomeIcon icon={["fas", "search"]} />

  return (
      <main>
        <div className="wrapper">
          <div className="content-container">
              <div className="landing-header">
                <div className="landing-header-content">
                    <h1>The best deals in golf.</h1>

                    <div className="form-grid form-grid-75">
                      <Dropdown overlay={menu} trigger={[query]} className="landing-search-dropdown">
                        <div className="rc-search">
                            <span className="input-container">
                                <Input 
                                  placeholder="Search by course, city, or type of deal..."
                                  onChange={handleInputChange} 
                                />
                            </span>
                            <span className="button-container">
                                <Button type="primary" shape="rounded" icon={searchIcon}>
                                    Search
                                </Button>
                            </span>
                        </div>
                      </Dropdown>
                    </div>

                    <div className="course-cards">
                      {jsxExampleCourses}
                    </div>

                </div>
            </div>
          </div>
        </div>

            <div className="wrapper" style={{marginTop: "50px"}}>
              <Facilities />
            </div>

            <EmailSignUp />
      </main>
  );
}
