import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { GetBooking, PutCompleteBooking } from "api/rpc";
import { StatusCode } from "api/protocols";
import SecondaryMenu from "components/ui/secondaryMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LocalStorage } from "api/localstorage";
import { Confirmation } from "elements/teetime/confirmation";

import { useParams } from "react-router";

import "public/scss/teetimes.scss";

interface ITeetimeList {
  id: number;
  date: string;
  time: number;
  holes: number;
  original_price_coins: number;
  price_coins: number;
  spacesAvailable: number;
  selected: boolean;
  allowedGroupSizes: [];
}

interface IBookingState {
  start_time: string;
  quantity: number;
  holes_booked: number;
  power_cart_quantity: number;
  cart_token: string;
  long_name: string;
  source_confirmation: string;
}

type IUseParams = {
  confirmationToken: any;
};

export default function BookingConfirmation(props: any) {
  const { confirmationToken } = useParams<IUseParams>();

  const history = useHistory();
  const [bookingState, setBookingState] = useState<IBookingState>({
    start_time: null,
    quantity: null,
    holes_booked: null,
    power_cart_quantity: null,
    cart_token: null,
    long_name: null,
    source_confirmation: null,
  });

  useEffect(() => {
    void GetBookingObject();
  }, []);

  async function GetBookingObject() {
    const res = await GetBooking("confirmation=" + confirmationToken + "&extended=true", true);
    if (res.status !== StatusCode.OK) {
      return;
    }

    const booking = res.data.data[0];
    console.log(res.data.data[0]);

    setBookingState((prev) => ({
      ...prev,
      token: booking.token,
      long_name: booking.facility.long_name,
      start_time: booking.start_time,
      quantity: booking.quantity,
      holes_booked: booking.holes_booked,
      power_cart_quantity: booking.power_cart_quantity,
      cart_token: booking.cart.cart_token,
      source_confirmation: booking.source_confirmation,
    }));
  }

  function backToTeetimes() {
    history.push(`/bookings`);
  }

  return (
    <>
      <SecondaryMenu
        title="Checkout - <br><span style='color: #999'>Booking Overview - <br>Order Confirmation</span>"
        balance={props.authStore.user?.points?.balance}
        initials={props.authStore.user?.initials}
        previousAction={backToTeetimes}
        icon={["fal", "times"]}
      />
      <main className="checkout-main">
        <div className="wrapper">
          <div className="focus-modal">
            <div className="focus-modal-section">
              <Confirmation {...bookingState} />
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
