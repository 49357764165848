import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as authActions from "actions/auth";
import Menu from "components/ui/menu";

const mapDispatchToProps = (dispatch: any) => {
  return {
    authActions: bindActionCreators(
      {
        ...authActions,
      },
      dispatch,
    ),
  };
};

const mapStateToProps = (state: any) => {
  const { authStore } = state;
  return {
    authStore: { ...authStore },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
