import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Moment from "moment";
import "public/scss/focusModal.scss";
import "public/scss/booking.scss";

export function Confirmation(props: any) {
  return (
    <div>
      <div className="focus-modal-section-header">
        <h1>
          <FontAwesomeIcon icon={["far", "check-circle"]} className="text-primary" />
          Booking Confirmed
        </h1>
        <p>Please present this reciept in the pro shop when you arrive at the course.</p>
      </div>

      <div className="section-info">
        <h6>Summary:</h6>
        <h4>{props.long_name}</h4>
        <p>
          <FontAwesomeIcon icon={["far", "calendar"]} className="text-primary" />
          {Moment(props.start_time).format("dddd, MMMM Do YYYY")} at{" "}
          <span className="text-primary text-bold text-underline"> {Moment(props.start_time).format("h:mm a")}</span>
        </p>
      </div>
      <div className="section-info">
        <h6>Valid for:</h6>{" "}
        <p>
          <FontAwesomeIcon icon={["far", "flag"]} className="text-primary" />
          {props.holes_booked} Holes
        </p>
      </div>
      {props.power_cart_quantity > 0 ? (
        <div className="section-info">
          <h6>Valid for:</h6>{" "}
          <p>
            <FontAwesomeIcon icon={["far", "car"]} className="text-primary" />
            {props.power_cart_quantity} Cart{props.power_cart_quantity > 1 && "s"}
          </p>
        </div>
      ) : null}
      <div className="section-info">
        <h6>Confirmation Code:</h6>
        <div className="confirmation-code">
          <p>{props.source_confirmation}</p>
        </div>
      </div>
    </div>
  );
}
