import * as React from "react";
import { render } from "react-dom";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import reducer from "./reducers";

import App from "containers/app";
import Errorbox from "containers/errorbox";
import Loader from "containers/loader";

import "public/scss/_variables.scss";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { fad } from "@fortawesome/pro-duotone-svg-icons";

library.add(fab, fal, far, fas, fad);

export const store = createStore(reducer, applyMiddleware(thunk));

const Root = () => {
  return (
    <Provider store={store}>
      <Loader />
      <Errorbox uiActions={""} showError={false} errorMessage="" />
      <App />
    </Provider>
  );
};

render(<Root />, document.getElementById("root"));
