export interface ITeetimeAction {
  type: string;
  payload: any;
}

export interface ILandingState {
  facilities: any;
}

export const actionTypes = {
  RETRIEVE_FACILITIES_SUCCESS: "RETRIEVE_FACILITIES_SUCCESS",
  RETRIEVE_FACILITIES_ERROR: "RETRIEVE_FACILITIES_ERROR",
};

const initialState: ILandingState = {
  facilities: [],
};

export default function LandingReducer(state = initialState, action: ITeetimeAction) {
  switch (action.type) {
    case actionTypes.RETRIEVE_FACILITIES_SUCCESS:
      return { ...state, facilities: action.payload };

    default:
      return state;
  }
}
