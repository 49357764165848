import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import {
  GetBooking,
  PutCompleteBooking,
  PostTransactionIntent,
  PostCheckoutCart,
  calculatePointsDifference,
} from "api/rpc";
import { StatusCode } from "api/protocols";
import SecondaryMenu from "components/ui/secondaryMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LocalStorage } from "api/localstorage";
import { BookingDetails } from "elements/teetime/bookingDetails";
import { ContinueCartButton } from "elements/cart/continueCartButton";
import BookingCart from "containers/teetime/bookingCart";
import PaymentMethods from "elements/paymentMethods";

import { useStripe } from "@stripe/react-stripe-js";

import { useParams } from "react-router";

import { Button } from "elements/button/index";
import { Alert } from "elements/alert/index";

import "public/scss/teetimes.scss";

import { enqueue, dequeue } from "actions/ui";
import { store } from "index";

interface ITeetimeList {
  id: number;
  date: string;
  time: number;
  holes: number;
  original_price_coins: number;
  price_coins: number;
  spacesAvailable: number;
  selected: boolean;
  allowedGroupSizes: [];
}

interface IBookingState {
  start_time: string;
  quantity: number;
  holes_booked: number;
  power_cart_quantity: number;
  cart_token: string;
  long_name: string;
  cart: any;
}

type IUseParams = {
  bookingToken: any;
};

export default function BookingReview(props: any) {
  const { bookingToken } = useParams<IUseParams>();
  const stripe = useStripe();
  const history = useHistory();
  const [bookingState, setBookingState] = useState<IBookingState>({
    start_time: null,
    quantity: null,
    holes_booked: null,
    power_cart_quantity: null,
    cart_token: null,
    long_name: null,
    cart: null,
  });

  const [paymentWarning, setPaymentWarning] = useState(false);
  const [loading, setLoading] = useState(false);
  const [differenceToken, setDifferenceToken] = useState(null);

  useEffect(() => {
    void GetBookingObject();
  }, []);

  async function GetBookingObject() {
    try {
      const res = await GetBooking("token=" + bookingToken + "&extended=true", true);

      console.log("GetBooking: ", res);

      if (res.status !== StatusCode.OK) {
        return;
      }

      const booking = res.data.data[0];
      console.log(res.data.data[0]);

      setBookingState((prev) => ({
        ...prev,
        long_name: booking.facility.long_name,
        token: booking.token,
        start_time: booking.start_time,
        quantity: booking.quantity,
        holes_booked: booking.holes_booked,
        power_cart_quantity: booking.power_cart_quantity,
        cart_token: booking.cart.cart_token,
        cart: booking.cart,
      }));
    } catch (err) {
      console.log("err", err);
    }
  }

  function goBack() {
    history.goBack();
  }

  async function postCheckout(cart_token: string, paymenIntentId: string, transactionId: number) {
    try {
      const checkoutRes = await PostCheckoutCart(cart_token, paymenIntentId, transactionId, false);

      console.log("PostCheckoutCart", checkoutRes);

      if (checkoutRes.status !== 200) {
        // Handle some error message to the user
      }

      LocalStorage.remove("cart_token");

      // Once cart is complete, complete the booking
      completeBooking();
    } catch (err) {
      console.log("err", err);
    }
  }

  async function continueToCheckout() {
    setLoading(true);

    try {
      const transactionIntent = await PostTransactionIntent(differenceToken, false);

      console.log("PostTransactionIntent", transactionIntent);
      // Make sure there is a payment method
      if (props.checkoutStore.selectedPaymentMethod) {
        enqueue()(store.dispatch, store.getState);

        const card = await stripe.confirmCardPayment(transactionIntent.data.data.payment_intent.client_secret, {
          payment_method: props.checkoutStore.selectedPaymentMethod.stripe_payment_method_id,
        });

        switch (card.error?.type) {
          case "api_connection_error":
            console.log("api connection error");
            setLoading(false);
            dequeue()(store.dispatch, store.getState);
            props.UIActions.showError("Connection error.");
            break;

          case "api_error":
            console.log("api error");
            setLoading(false);
            dequeue()(store.dispatch, store.getState);
            props.UIActions.showError("Connection error.");
            break;

          case "authentication_error":
            console.log("authentication error");
            setLoading(false);
            dequeue()(store.dispatch, store.getState);
            props.UIActions.showError("Request declined.");
            break;

          case "card_error":
            console.log("card error");
            setLoading(false);
            dequeue()(store.dispatch, store.getState);
            props.UIActions.showError(card.error.message);
            break;

          case "idempotency_error":
            console.log("idempotency error");
            setLoading(false);
            dequeue()(store.dispatch, store.getState);
            props.UIActions.showError("Error.");
            break;

          case "invalid_request_error":
            console.log("invalid request error");
            setLoading(false);
            dequeue()(store.dispatch, store.getState);
            props.UIActions.showError("Request error.");
            break;

          case "rate_limit_error":
            console.log("rate limit error");
            setLoading(false);
            dequeue()(store.dispatch, store.getState);
            props.UIActions.showError("Rate limit error.");
            break;

          case "validation_error":
            console.log("validation error");
            setLoading(false);
            dequeue()(store.dispatch, store.getState);
            props.UIActions.showError("Validation error.");
            break;
        }

        console.log("stripe.confirmCardPayment", card);

        // Handle result.paymentIntent
        if (card.paymentIntent) {
          const thing = await postCheckout(differenceToken, card.paymentIntent.id, 0);
          console.log("postCheckout", thing);
        }
      } else {
        setLoading(false);
        setPaymentWarning(true);
      }
      setLoading(false);
    } catch (err) {
      console.log("error", err);
      setLoading(false);
    }
  }

  async function completeBooking() {
    try {
      setLoading(true);
      // enqueue()(store.dispatch, store.getState);

      const res = await PutCompleteBooking("token=" + bookingToken, false);

      console.log("PutCompleteBooking", res);

      if (res.status !== StatusCode.OK) {
        setLoading(false);
        return;
      }

      // force refresh
      props.authActions.refreshUser(true);

      setLoading(false);
      // navigate to the booking page to review their booking
      dequeue()(store.dispatch, store.getState);
      history.push(`/confirmation/${res.data.data.confirmation}`);
      // dequeue()(store.dispatch, store.getState);
    } catch (err) {
      console.log("err", err);
    }
  }

  // clear the payment method warning
  useEffect(() => {
    let mounted = true;

    if (props.checkoutStore.selectedPaymentMethod) {
      if (mounted) {
        setPaymentWarning(false);
      }
    }

    return () => {
      mounted = false;
    };
  }, [props.checkoutStore.selectedPaymentMethod]);

  const [init, setInit] = useState(false);

  // get the differenceToken once
  useEffect(() => {
    let mounted = true;

    const getPointsDifference = async () => {
      try {
        const cart_token = bookingState.cart_token;

        if (cart_token) {
          const differenceRes = await calculatePointsDifference(cart_token);

          console.log("calculatePointsDifference", differenceRes);

          if (differenceRes.data.data !== null) {
            const differenceToken = differenceRes.data.data.cart_token;

            if (mounted) {
              setDifferenceToken(differenceToken);
              setInit(true);
            }
          }
        }
      } catch (err) {
        console.log("err", err);
      }
    };

    if (!init) {
      getPointsDifference();
    }

    return () => {
      mounted = false;
    };
  });

  return (
    <>
      <SecondaryMenu
        title={'Checkout - <span style="color: #999"> <br>Booking Overview</span>'}
        balance={props.authStore.user?.points?.balance}
        initials={props.authStore.user?.initials}
        previousAction={goBack}
        icon={["far", "arrow-left"]}
      />
      <main className="checkout-main">
        <div className="wrapper">
          <div className="focus-modal">
            <div className="focus-modal-section">
              <BookingDetails {...bookingState} />
              {props.authStore?.user?.points.balance < props.cartStore.total_price && (
                <div>
                  <div className="border-top"></div>
                  <PaymentMethods {...props} />
                  {paymentWarning && <Alert message="Please select a payment method" type="warning" />}
                </div>
              )}
            </div>
            <div className="focus-modal-section">
              <BookingCart
                {...props}
                allowQuantityChange={false}
                cart={bookingState.cart}
                cartToken={bookingState.cart_token}
                displayWalletBalance={true}
              />
              {/* Need to show different buttons based on the funds available in wallet */}
              {props.authStore?.user?.points?.balance < props.cartStore.total_price ? (
                // <ContinueCartButton title="Purchase Coins & Complete Booking" onClick={continueToCheckout} />
                <Button block type="primary" shadow loading={loading} onClick={() => continueToCheckout()}>
                  Purchase Coins & Complete Booking
                </Button>
              ) : (
                <Button block type="primary" shadow loading={loading} onClick={() => completeBooking()}>
                  Complete Booking
                </Button>
              )}
              {props.UIStore.showError && (
                <Alert message={props.UIStore.errorMessage} type="warning" style={{ marginTop: 12 }} />
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
