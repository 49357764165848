import React, { useEffect, useState } from "react";
import Search from "./Search";
import classNames from "classnames";
import "./style.scss";

export interface InputProps {
  className?: any;
  placeholder?: string;
  onChange?: (value: any) => void;
  value?: string;
  disabled?: boolean;
  type?: "text" | "password" | "email" | "search";
  prefixCls?: string;
  id?: string;
  addonAfter?: React.ReactNode;
}

interface IInputComposition {
  Search: React.FC;
}

export const Input: React.FC<InputProps> = (props) => {
  const { prefixCls = "rc-input", type = "text", className, onChange, addonAfter, ...rest } = props;

  console.log("onchange", onChange);

  const [value, setValue] = useState("");

  const handleChange = (e: any) => {
    const newValue = e.target.value;
    setValue(newValue);
  };

  useEffect(() => {
    let mounted = true;

    if (onChange) {
      if (mounted) {
        onChange(value);
      }
    }

    return () => {
      mounted = false;
    };
  }, [value]);

  const inputClassName = classNames(
    prefixCls,
    {
      [`${prefixCls}-${type}`]: type,
    },
    className,
  );

  return <input type={type} value={value} onChange={(e) => handleChange(e)} {...rest} className={inputClassName} />;
};

export default Input;
