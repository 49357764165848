import React, { useState, createContext, useContext, useEffect } from "react";
import classNames from "classnames";
import "./style.css";
import { faGameConsoleHandheld } from "@fortawesome/pro-duotone-svg-icons";

export type MenuMode = "vertical" | "vertical-left" | "vertical-right" | "horizontal" | "inline";

interface IMenuContext {
  state: string;
  setState: (label: string) => void;
}

export const MenuContext = createContext<IMenuContext | undefined>(undefined);

interface IMenuComposition {
  Item: React.FC<IItemProps>;
}

interface IMenuProps {
  className?: string;
}

export const Menu: React.FC<IMenuProps> & IMenuComposition = (props) => {
  const [state, setState] = useState("a");

  const { className, ...rest } = props;

  const menuClassname = classNames(`rc-menu`, className);

  return (
    <MenuContext.Provider value={{ state, setState }}>
      <ul className={menuClassname}>{props.children}</ul>
    </MenuContext.Provider>
  );
};

interface IItemProps {
  className?: string;
  prefixCls?: string;
  key?: any;
}

const Item: React.FC<IItemProps> = (props) => {
  const { prefixCls = "rc-menu-item", className, ...rest } = props;

  const itemClassname = classNames(prefixCls, className);

  const { setState } = useContext(MenuContext);

  return (
    <li className={itemClassname} {...rest}>
      {props.children}
    </li>
  );
};

Menu.Item = Item;
