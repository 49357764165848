import { APIGet, APIPost, APIPut, APIDelete, WebGet, WebPost, GetExternal } from "./protocols";
import { IUserRegister } from "components/auth/register";
import axios from "axios";

const API_VERSION = "2021-07";

export async function FetchCSRFCookie(useGlobalLoader: boolean) {
  return await WebGet("sanctum/csrf-cookie", useGlobalLoader);
}

export async function UserLogin(email: string, password: string, useGlobalLoader: boolean) {
  return await WebPost("login", { email, password }, useGlobalLoader);
}

export async function UserRegister(userData: IUserRegister) {
  return await APIPost(`${API_VERSION}/marketplace/user/register`, userData, true);
}

export async function UserActive(useGlobalLoader: boolean) {
  return await APIGet(`${API_VERSION}/marketplace/user/active`, useGlobalLoader);
}

export async function UserLogout(useGlobalLoader: boolean) {
  return await WebPost("logout", {}, useGlobalLoader);
}

export async function GetUserPaymentMethod(useGlobalLoader: boolean) {
  return await APIGet(`${API_VERSION}/marketplace/user/payment-method`, useGlobalLoader);
}

export async function SavePaymentMethod(paymentMethodID: string, useGlobalLoader: boolean) {
  return await APIPost(
    `${API_VERSION}/marketplace/user/payment-method?payment_method_id=${paymentMethodID}`,
    useGlobalLoader,
  );
}

export async function GetPaymentMethodSetupIntent(useGlobalLoader: boolean) {
  return await APIPost(`${API_VERSION}/marketplace/user/payment-method/setup`, useGlobalLoader);
}

export async function PostTransactionIntent(token: string, useGlobalLoader: boolean) {
  return await APIPost(`${API_VERSION}/marketplace/cart/transaction/intent?cart_token=${token}`, useGlobalLoader);
}

export async function GetGoogleGeoDetails(param: string, useGlobalLoader: boolean) {
  return await GetExternal(
    `https://maps.googleapis.com/maps/api/geocode/json?${param}&key=${process.env.GOOGLE_MAP_API}`,
    useGlobalLoader,
  );
}

export async function GetPointsPackages() {
  return await APIGet(`${API_VERSION}/guest/marketplace/point/package`, true);
}

export async function GetCart(cart_token?: string, useGlobalLoader?: boolean) {
  return await APIGet(`${API_VERSION}/marketplace/cart?cart_token=${cart_token}`, useGlobalLoader);
}

export async function PostCartNew(useGlobalLoader?: boolean) {
  return await APIPost(`${API_VERSION}/marketplace/cart/new`, useGlobalLoader);
}

export async function PostCartLineItem(token: string, q: number, var_id: number, increase?: boolean) {
  return await APIPost(
    `${API_VERSION}/marketplace/cart/line-item`,
    { cart_token: token, quantity: q, variant_id: var_id, increase_quantity: increase ? "1" : "0" },
    true,
  );
}

export async function DeleteCartLineItem(token: string, line_item_id: number) {
  return await APIDelete(`${API_VERSION}/marketplace/cart/line-item?cart_token=${token}&id=${line_item_id}`, true);
}

export async function calculatePointsDifference(token: string) {
  return await APIGet(`${API_VERSION}/marketplace/point/difference?cart_token=${token}`);
}

export async function GetFacilities(param: string) {
  return await APIGet(`${API_VERSION}/marketplace/facility?&tee_times=true` + param);
}

const request = async (url?: any, method?: any, params?: any, headers?: any) => {
  return await axios({
    baseURL: process.env.API_BASE_URL,
    withCredentials: true,
    url,
    method,
    headers: Object.assign(
      {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      headers,
    ),
    params,
  });
};

const auth_request = async (url?: any, method?: any, data?: any) => {
  const idToken = "token";

  return await request(url, method, data, {
    Authorization: `Bearer ${idToken}`,
  });
};

export const getFacilities = async () => {
  return await request(`/api/${API_VERSION}/marketplace/facility`, "GET");
};

export const queryFacilities = async (query: string) => {
  console.log("query", query);
  try {
    const cityRes = await request(`/api/${API_VERSION}/marketplace/facility`, "GET", {
      search: query,
    });

    console.log(cityRes);

    return cityRes;
  } catch (error) {
    console.log(error);
  }
};

export async function GetOrder(id: number) {
  return await APIGet(`${API_VERSION}/marketplace/order?id=${id}`);
}

export async function PostCheckoutCart(
  cart_token: string,
  payment_intent_id: string,
  transaction_id: number,
  useGlobalLoader = true,
) {
  return await APIPost(
    `${API_VERSION}/marketplace/cart/checkout?cart_token=${cart_token}&payment_intent_id=${payment_intent_id}`,
    {},
    useGlobalLoader,
  );
}

// Tee Times
export async function GetTeetimes(
  facility_id: number,
  holes?: number,
  start_date?: string,
  start_time?: string,
  players?: number,
) {
  return await APIGet(
    `${API_VERSION}/marketplace/tee-time?facility_id=${facility_id}&holes=${holes}&start_date=${start_date}`,
  );
}

export const getTeeTimes = async (
  facility_id: number,
  holes?: number,
  start_date?: string,
  start_time?: string,
  players?: number,
  points?: number,
  end_time?: string,
) => {
  return await request(`/api/${API_VERSION}/marketplace/tee-time`, "GET", {
    facility_id,
    holes,
    start_date,
    start_time,
    players,
    points,
    end_time,
  });
};

export async function PostBooking(
  facility_id: number,
  start_date: string,
  start_time: string,
  nine_code: string,
  holes: number,
) {
  return await APIPost(
    `${API_VERSION}/marketplace/tee-time/booking?facility_id=` +
      facility_id +
      "&start_date=" +
      start_date +
      "&start_time=" +
      start_time +
      "&nine_code=" +
      nine_code +
      "&holes_booked=" +
      holes,
    {},
    true,
  );
}

// Tee Time Bookings
export async function GetBooking(param?: string, useGlobalLoader?: boolean) {
  // params include id: number, token: string extended: boolean,
  return await APIGet(`${API_VERSION}/marketplace/tee-time/booking?` + param, useGlobalLoader);
}

export async function PutCompleteBooking(param: string, useGlobalLoader: boolean) {
  return await APIPut(`${API_VERSION}/marketplace/tee-time/booking/complete?` + param, useGlobalLoader);
}

export async function PutBooking(
  token: string,
  holes_booked: number,
  playerQuantity: number,
  powerCartQuantity: number,
) {
  return await APIPut(
    `${API_VERSION}/marketplace/tee-time/booking?token=` +
      token +
      "&holes_booked=" +
      holes_booked +
      "&quantity=" +
      playerQuantity +
      "&power_cart_quantity=" +
      powerCartQuantity,
    {},
    true,
  );
}

export const CancelBooking = async (token: string) => {
  return await APIPut(`/${API_VERSION}/marketplace/tee-time/booking/cancel?token=` + token, true);
};

export async function GetFacility(param: string, useGlobalLoader: boolean) {
  return await APIGet(`${API_VERSION}/marketplace/facility?` + param, useGlobalLoader);
}

export const sendResetLink = async (email: any) => {
  return await request(`/api/${API_VERSION}/marketplace/user/reset-code`, "POST", {
    email,
  });
};

export const resetPassword = async (password: string, email: string, code: string) => {
  return await request(`/api/${API_VERSION}/marketplace/user/reset`, "PUT", {
    email,
    code,
    password,
  });
};

export const clubhouse = {
  customers: {
    login: UserLogin,
    register: UserRegister,
    active: UserActive,
    logout: UserActive,
  },
  facility: {
    retrieve: GetFacility,
  },
  facilities: {
    retrieve: getFacilities,
    query: queryFacilities,
  },
  teetimes: {
    retrieve: getTeeTimes,
  },
  booking: {
    retrieve: GetBooking,
    add: PostBooking,
    update: PutBooking,
    complete: PutCompleteBooking,
  },
};
