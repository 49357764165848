import { connect } from "react-redux";
import Loader from "components/ui/loader";

const mapStateToProps = (state: any) => {
  return {
    uiStore: { ...state.uiStore },
  };
};

export default connect(mapStateToProps)(Loader);
