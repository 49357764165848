import React, { useState, createContext, useContext, useEffect } from "react";
import classNames from "classnames";
import "./style.css";

interface IRadioContext {
  value: string | number;
  setValue: (value: string | number) => void;
}

export const RadioGroupContext = createContext<IRadioContext | undefined>(undefined);

interface IRadioProps {
  className?: string;
  prefixCls?: string;
}

interface IGroupProps {
  name: string;
  className?: string;
  prefixCls?: string;
  defaultValue?: any;
  type?: number;
  onChange?: (value: any) => void;
}

interface IButtonProps {
  name: any;
  value: any;
  className?: string;
  prefixCls?: string;
  disabled?: boolean;
  key?: any;
}

interface IRadioComposition {
  Group: React.FC<IGroupProps>;
  Button: React.FC<IButtonProps>;
}

export const Radio: React.FC<IRadioProps> & IRadioComposition = props => {
  const { children, prefixCls = "rc-radio", className } = props;

  const radioClassName = classNames(prefixCls, className);

  return <div className={radioClassName}>{children}</div>;
};

const Group: React.FC<IGroupProps> = props => {
  const { name, prefixCls = "rc-radio-group", className, defaultValue, type, onChange, children } = props;

  const [value, setValue] = useState<any | undefined>(defaultValue);

  const radioGroupClassName = classNames(prefixCls, { [`${prefixCls}-t${type}`]: type }, className);

  useEffect(() => {
    let mounted = true;

    if (onChange) {
      if (mounted) {
        onChange(value);
      }
    }

    return () => {
      mounted = false;
    };
  }, [value]);

  return (
    <RadioGroupContext.Provider value={{ value, setValue }}>
      <div id={name} className={radioGroupClassName}>
        {children}
      </div>
    </RadioGroupContext.Provider>
  );
};

const Button: React.FC<IButtonProps> = props => {
  const { name, value, prefixCls = "rc-radio-button", className, disabled, children, ...rest } = props;

  const radioStore = useContext(RadioGroupContext);

  const selectRadio = () => {
    radioStore.setValue(value);
  };

  const radioInputClassName = classNames(`${prefixCls}-input`, className);

  const radioLabelClassName = classNames(
    `${prefixCls}-label`,
    { [`${prefixCls}-label-checked`]: radioStore.value === value },
    { [`${prefixCls}-label-disabled`]: disabled },
  );

  return (
    <React.Fragment {...rest}>
      <label htmlFor={`${name}-${value}`} className={radioLabelClassName}>
        <span className="rc-radio-button">
          <input
            type="radio"
            id={`${name}-${value}`}
            name={name}
            className={radioInputClassName}
            onClick={selectRadio}
            disabled={disabled}
          />
        </span>

        <span className="rc-radio-text">{children}</span>
      </label>
    </React.Fragment>
  );
};

Radio.Group = Group;
Radio.Button = Button;

export default Radio;
