import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { PostTransactionIntent, PostCheckoutCart } from "api/rpc";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { LocalStorage } from "api/localstorage";

import SecondaryMenu from "components/ui/secondaryMenu";
import StripeWrapper from "components/stripeWrapper";

import Cart from "containers/cart";
import CartAction from "containers/cartAction";
import PaymentMethods from "elements/paymentMethods";
import { CompleteCartButton } from "elements/cart/completeCartButton";

import { Alert } from "../elements/alert/index";
import { Button } from "../elements/button/index";

import { store } from "index";
import { enqueue, dequeue } from "actions/ui";

import "public/scss/checkout.scss";
import "public/scss/focusModal.scss";

export default function Checkout(props: any) {
  const stripe = useStripe();
  const history = useHistory();

  function goBack() {
    props.history.goBack();
  }

  const [loading, setLoading] = useState(false);
  const [paymentWarning, setPaymentWarning] = useState(false);

  const completeCart = async () => {
    try {
      setLoading(true);
      const cart_token = LocalStorage.get("cart_token");

      const transactionIntent = await PostTransactionIntent(cart_token, false /*loader*/);

      // check for payment method
      if (props.checkoutStore.selectedPaymentMethod) {
        enqueue()(store.dispatch, store.getState);

        const card = await stripe.confirmCardPayment(transactionIntent.data.data.payment_intent.client_secret, {
          payment_method: props.checkoutStore.selectedPaymentMethod.stripe_payment_method_id,
        });

        switch (card.error?.type) {
          case "api_connection_error":
            console.log("api connection error");
            setLoading(false);
            dequeue()(store.dispatch, store.getState);
            props.UIActions.showError("Connection error.");
            break;

          case "api_error":
            console.log("api error");
            setLoading(false);
            dequeue()(store.dispatch, store.getState);
            props.UIActions.showError("Connection error.");
            break;

          case "authentication_error":
            console.log("authentication error");
            setLoading(false);
            dequeue()(store.dispatch, store.getState);
            props.UIActions.showError("Request declined.");
            break;

          case "card_error":
            console.log("card error");
            setLoading(false);
            dequeue()(store.dispatch, store.getState);
            props.UIActions.showError(card.error.message);
            break;

          case "idempotency_error":
            console.log("idempotency error");
            setLoading(false);
            dequeue()(store.dispatch, store.getState);
            props.UIActions.showError("Error.");
            break;

          case "invalid_request_error":
            console.log("invalid request error");
            setLoading(false);
            dequeue()(store.dispatch, store.getState);
            props.UIActions.showError("Request error.");
            break;

          case "rate_limit_error":
            console.log("rate limit error");
            setLoading(false);
            dequeue()(store.dispatch, store.getState);
            props.UIActions.showError("Rate limit error.");
            break;

          case "validation_error":
            console.log("validation error");
            setLoading(false);
            dequeue()(store.dispatch, store.getState);
            props.UIActions.showError("Validation error.");
            break;
        }

        if (card.paymentIntent) {
          await postCheckout(cart_token, card.paymentIntent.id, 0);
        }
      } else {
        setLoading(false);
        setPaymentWarning(true);
      }

      setLoading(false);
    } catch (err) {
      console.log("err", err);
    }
  };

  async function postCheckout(cart_token: string, paymenIntentId: string, transactionId: number) {
    const checkoutRes = await PostCheckoutCart(cart_token, paymenIntentId, transactionId, false);
    if (checkoutRes.status !== 200) {
      // Handle some error message to the user
    }

    // force refresh
    props.authActions.refreshUser(true);

    LocalStorage.remove("cart_token");

    dequeue()(store.dispatch, store.getState);

    props.history.push(`/order/${checkoutRes.data.data.order.id}`);
  }

  // clear the payment method warning
  useEffect(() => {
    let mounted = true;

    if (props.checkoutStore.selectedPaymentMethod) {
      if (mounted) {
        setPaymentWarning(false);
      }
    }

    return () => {
      mounted = false;
    };
  }, [props.checkoutStore.selectedPaymentMethod]);

  return (
    <>
      <SecondaryMenu
        title={"Checkout"}
        balance={props.authStore.user?.points?.balance}
        initials={props.authStore.user?.initials}
        previousAction={goBack}
        icon={["fal", "times"]}
      />
      <main className="checkout-main">
        <div className="wrapper">
          <div className="focus-modal">
            <div className="focus-modal-section">
              <h1>Checkout</h1>
              <PaymentMethods {...props} />
              {paymentWarning && <Alert message="Please select a payment method" type="warning" />}
            </div>

            <div className="focus-modal-section">
              <Cart
                {...props}
                allowQuantityChange={true}
                displayWalletBalance={false}
                cartToken={LocalStorage.get("cart_token")}
              />

              <Button shadow block type="primary" loading={loading} onClick={() => completeCart()}>
                Complete Purchase
              </Button>

              {props.UIStore.showError && (
                <Alert message={props.UIStore.errorMessage} type="warning" style={{ marginTop: 12 }} />
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
