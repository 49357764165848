import React from "react";
import { PostTransactionIntent, PostCheckoutCart } from "api/rpc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LocalStorage } from "api/localstorage";
import "public/scss/cart.scss";

export function CartNotification(props: any) {
  return (
    <div className="cart-notification">
      {props.icon ? <FontAwesomeIcon className={`cart-notification-icon ${props.iconType}`} icon={props.icon} /> : null}
      <p className="cart-notification-text">{props.content}</p>
    </div>
  );
}
