import { IUser } from "./models/generals";

export interface IAuthState {
  user: undefined | IUser;
  refresh: boolean;
}

export interface IAuthAction {
  type: string;
  payload: IUser;
}

const initialState: IAuthState = {
  user: undefined,
  refresh: false,
};

export default function AuthReducer(state = initialState, action: IAuthAction) {
  switch (action.type) {
    case "user.login":
      return {
        ...state,
        user: action.payload,
      };
    case "user.logout":
      return {
        ...state,
        user: undefined,
      };

    case "REFRESH_USER":
      return {
        ...state,
        refresh: action.payload,
      };

    default:
      return state;
  }
}
