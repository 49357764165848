import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { GetTeetimes, GetFacility } from "api/rpc";
import { StatusCode } from "api/protocols";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LocalStorage } from "api/localstorage";
import Facilities from "./facilities";

import { useParams } from "react-router";

import "public/scss/teetimes.scss";

export default function Index(props: any) {
  return (
    <main>
      <div className="wrapper">
        <section>
          <Facilities />
        </section>
      </div>
    </main>
  );
}
