import React from "react";
import "./style.scss";
import classNames from "classnames";

interface ITitleProps {
  level?: number;
  type?: number;
  className?: string;
  prefixCls?: string;
}

export const Title: React.FC<ITitleProps> = (props) => {
  const { level = 1, type, prefixCls = "rc-title", className, children, ...restProps } = props;

  const el = React.createElement;

  const titleClassName = classNames(
    prefixCls,
    `${prefixCls}-h${level}`,
    { [`${prefixCls}-h${level}-t${type}`]: type },
    className,
  );

  return el(`h${level}`, { className: titleClassName }, children);
};

interface ISubTitleProps {
  className?: string;
}

export const SubTitle: React.FC<ISubTitleProps> = (props) => {
  const { className, children } = props;

  const subtTitleClassName = classNames("rc-subtitle", className);

  return <p className={subtTitleClassName}>{children}</p>;
};
