import React, { useEffect } from "react";
import { GetGoogleGeoDetails } from "api/rpc";
import { StatusCode } from "api/protocols";

const defaultClientGeoLocation = {
  city: "Cambridge",
  state: "ON",
  lat: 43.3616211,
  lng: -80.3144276,
};

export interface IGeoDetails {
  city: string;
  state: string;
  lat: number;
  lng: number;
}

interface IGeoServiceProps {
  onLocationUpdate: (geoDetails: IGeoDetails) => void;
}

export default function GeoService(props: IGeoServiceProps): null {
  async function fetchGeoDetails(position: Position) {
    const res = await GetGoogleGeoDetails(`latlng=${position.coords.latitude},${position.coords.longitude}`, false);
    if (res.status !== StatusCode.OK || !res.data || !res.data.results || !res.data.results[0]) {
      return;
    }
    const result = res.data.results[0];
    if (result.address_components!.length === 0) {
      return;
    }

    let city = "";
    let state = "";

    for (const addrComponent of result.address_components) {
      if (addrComponent.types.includes("locality") || addrComponent.types.includes("sublocality")) {
        city = addrComponent.long_name;
      }
      if (addrComponent.types.includes("administrative_area_level_1") || addrComponent.types.includes("sublocality")) {
        state = addrComponent.short_name;
      }
    }
    props.onLocationUpdate({
      city,
      state,
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    });
  }

  useEffect(() => {
    props.onLocationUpdate(defaultClientGeoLocation);

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        void fetchGeoDetails(position);
      });
    }
  }, []);
  return null;
}
