import React from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./style.css";

interface IRateProps {
  value?: number;
  count?: number;
  style?: React.CSSProperties;
  prefixCls?: string;
  className?: string;
}

export const Rate: React.FC<IRateProps> = (props) => {
  const { value = 0, count = 5, prefixCls = "rc-rate", className } = props;

  const stars = [];

  for (let index = 0; index < count; index += 1) {
    const starValue = index + 1;

    if (value + 0.5 >= starValue && value < starValue) {
      stars.push(
        <li key={index}>
          <FontAwesomeIcon className="rating" icon={["fas", "star-half-alt"]} />
        </li>,
      );
    } else {
      starValue <= value
        ? stars.push(
            <li key={index}>
              <FontAwesomeIcon className="rating" icon={["fas", "star"]} />
            </li>,
          )
        : stars.push(
            <li key={index}>
              <FontAwesomeIcon className="rating" key={count} icon={["far", "star"]} />
            </li>,
          );
    }
  }

  const rateClassName = classNames(prefixCls, className);

  return (
    <>
      <ul className={rateClassName}>{stars}</ul>
    </>
  );
};
