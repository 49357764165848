import { GetUserPaymentMethod } from "api/rpc";
import { StatusCode } from "api/protocols";

export function fetchPaymentMethods() {
  return async (dispatch: any) => {
    const res = await GetUserPaymentMethod(false);
    if (res.status !== StatusCode.OK) {
      //TODO: handle error here
      console.log(res.data);
      //return;
    }
    dispatch({ type: "update.payment.methods", payload: res.data.data });
  };
}

export function updateSelectedPaymentMethod(newPaymentMethodID: object | null) {
  return (dispatch: any) => {
    dispatch({ type: "update.selected.payment.method", payload: newPaymentMethodID });
  };
}
