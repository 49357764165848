import { connect } from "react-redux";
import Checkout from "components/checkout";
import { bindActionCreators } from "redux";
import * as CheckoutActions from "actions/checkout";
import * as CartActions from "actions/cart";
import * as AuthActions from "actions/auth";
import * as UIActions from "actions/ui";

const mapStateToProps = (state: any) => {
  return {
    checkoutStore: { ...state.checkoutStore },
    cartStore: { ...state.cartStore },
    authStore: { ...state.authStore },
    UIStore: { ...state.uiStore },
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    checkoutActions: bindActionCreators(
      {
        ...CheckoutActions,
      },
      dispatch,
    ),
    cartActions: bindActionCreators(
      {
        ...CartActions,
      },
      dispatch,
    ),
    authActions: bindActionCreators(
      {
        ...AuthActions,
      },
      dispatch,
    ),
    UIActions: bindActionCreators(
      {
        ...UIActions,
      },
      dispatch,
    ),
  };
};

export default connect<any>(mapStateToProps, mapDispatchToProps)(Checkout);
