import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { GetPointsPackages, GetCart, PostCartLineItem } from "api/rpc";
import { StatusCode } from "api/protocols";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LocalStorage } from "api/localstorage";

import "public/scss/buyCoins.scss";

interface ICoinList {
  id: number;
  title: string;
  price: number;
  points: number;
  bonus_points?: number;
  available_on?: Date;
  unavailable_on?: Date;
  product_id?: number;
  variant_id?: number;
  position?: number;
  description?: string;
  promotion?: boolean;
  best_value?: boolean;
  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date;
}

interface ICoinsListState {
  isListLoaded: boolean;
  error: any;
  List: ICoinList[];
}

export default function BuyCoins(props: any) {
  const [coinState, setCoinState] = useState<ICoinsListState>({
    isListLoaded: false,
    error: null,
    List: [],
  });

  function addToCart(variant_id: number) {
    const cart_token = LocalStorage.get("cart_token");
    props.cartActions.updateCartLineItem(cart_token, 1, variant_id, true);
  }

  async function GetCoinsList() {
    const res = await GetPointsPackages();
    if (res.status !== StatusCode.OK) {
      return;
    }
    setCoinState(prev => ({
      ...prev,
      isListLoaded: true,
      List: res.data.data,
    }));
  }

  useEffect(() => {
    void GetCoinsList();
    props.cartActions.fetchCart(LocalStorage.get("cart_token"));
  }, []);

  function MapCoinList(OItem: ICoinList, index: number) {
    return (
      <button
        key={index}
        className={OItem.best_value ? "best-value-button" : null}
        style={{
          cursor: props.authStore.user ? "pointer" : "not-allowed",
          opacity: props.authStore.user ? "1" : "0.3",
        }}
        title={props.authStore.user ? "Click to add to cart" : "You need to be logged in to buy points."}
        onClick={props.authStore.user ? () => addToCart(OItem.variant_id) : null}
      >
        <div className="coins-item-content">
          <div className="icon-coins-price-container">
            <FontAwesomeIcon icon={["fal", "plus-circle"]} />
            <p>{OItem.points} Coins</p>
            <p>${OItem.price.toFixed(2)}</p>
          </div>
          {OItem.bonus_points ? <p className="bonus-points">+{OItem.bonus_points} Bonus Coins</p> : null}
          {/* {OItem.best_value ? (
            <p className="savings-text">
              <FontAwesomeIcon icon={["fal", "sack-dollar"]} />
              <span>Over $100 in savings!</span> <span>$500</span>
            </p>
          ) : null} */}
        </div>
        {OItem.best_value ? <p className="best-value-ribbon">Best Value</p> : null}
      </button>
    );
  }

  return (
    <main>
      <div className="wrapper">
        <section>
          <h1>Buy Coins</h1>
          <p className="secondary-p">
            Take control of your golf savings with the all new <a href="#">Clubhouse Coins</a> - purchase, earn, and
            redeem.
          </p>
          <p className="tertiary-p">Select from Coin Packs below to purchase:</p>
          {!props.authStore.user ? <p className="secondary-p">You must be logged in to buy coins.</p> : null}
          <div className="coin-container">
            {
              coinState.isListLoaded
                ? coinState.List.map((OListItem: ICoinList, i: number) => MapCoinList(OListItem, i))
                : "No coins are currently for sale." //TODO: make look nicer
            }
          </div>
        </section>
      </div>
      {props.authStore.user && props.cartStore.line_item_count > 0 && (
        <a href="/checkout" className="snackbar">
          <div className="coin-checkout-container">
            <p>
              <span>Continue to Checkout</span>
              <FontAwesomeIcon icon={["fal", "arrow-right"]} />
            </p>
          </div>
          <div className="coin-cart">
            <FontAwesomeIcon icon={["fal", "shopping-cart"]} />
            <span>{props.cartStore.line_item_count}</span>
          </div>
        </a>
      )}
      <section className="how-it-works-section">
        <div className="wrapper">
          <h5>How It Works</h5>
          <h2>What are Clubhouse Coins?</h2>
          <p>
            The all new digital currency used to maximize your golf experience.<br></br>Clubhouse Coins is the premium
            way to pay for your next golf round.
          </p>
          <div className="three-icon-container">
            <div className="icon-container">
              <div className="svg-container">
                <FontAwesomeIcon icon={["fal", "shopping-cart"]} />
              </div>
              <h5>Purchase</h5>
              <p>Choose a package that suits your golfing needs or trial the program with a one-time purchase.</p>
            </div>
            <div className="icon-container">
              <div className="svg-container">
                <FontAwesomeIcon icon={["fal", "shopping-cart"]} />
              </div>
              <h5>Redeem</h5>
              <p>Choose where and when you want to play. Redeem your coins for limitless golf deals.</p>
            </div>
            <div className="icon-container">
              <div className="svg-container">
                <FontAwesomeIcon icon={["fal", "shopping-cart"]} />
              </div>
              <h5>Play</h5>
              <p>No payment required at the pro shop, just present your confirmation for seamless check in.</p>
            </div>
          </div>
          <a href="/how-it-works" className="button-primary">
            Learn More <FontAwesomeIcon icon={["fal", "arrow-right"]} />
          </a>
        </div>
      </section>
    </main>
  );
}
