import React, { useEffect, useState } from "react";
import classNames from "classnames";
import "elements/popover/popover.scss";

const Pane = (props: any) => (
  <div className="ui-popover_pane ui-popover_scrollable ui-popover_scrollable-vertical">{props.children}</div>
);

const Section = (props: any) => <div className="ui-popover_section">{props.children}</div>;

const Popover = (props: any) => (
  <>
    {props.activator}
    <div
      className={classNames("ui-popover_overlay ui-positioned-overlay", {
        "ui-popover_overlay--open": props.active,
      })}
    >
      <div className="ui-popover">
        <div className="ui-popover_wrapper">
          <div className="ui-popover_content">
            {props.sectioned ? (
              <>
                {React.Children.map(props.children, child => {
                  if (child.type.displayName === "Pane") {
                    return <>{child}</>;
                  } else {
                    return <Section>{props.children}</Section>;
                  }
                })}
              </>
            ) : (
              <>{props.children}</>
            )}
          </div>
        </div>
      </div>
    </div>
  </>
);

Pane.displayName = "Pane";
Section.displayName = "Section";
Popover.Pane = Pane;
Popover.Section = Section;

export default Popover;
