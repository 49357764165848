import { actionTypes } from "./landing";

export interface IBookingAction {
  type: string;
  payload: any;
}

export interface IBookingState {
  holes: number;
  playerQuantity: number;
  cartQuantity: number;
  cart: boolean;
}

export const bookingActionTypes = {
  UPDATE_BOOKING: "UPDATE_BOOKING",
  UPDATE_HOLES: "UPDATE_HOLES",
  UPDATE_PLAYER_QUANTITY: "UPDATE_PLAYER_QUANTITY",
  UPDATE_CART_QUANTITY: "UPDATE_CART_QUANTITY",
  UPDATE_CART: "UPDATE_CART",
};

const initialState: IBookingState = {
  holes: 18,
  playerQuantity: 2,
  cartQuantity: 0,
  cart: false,
};

export default function BookingReducer(state = initialState, action: IBookingAction) {
  switch (action.type) {
    case bookingActionTypes.UPDATE_BOOKING:
      return { ...state, ...action.payload };

    case bookingActionTypes.UPDATE_HOLES:
      return { ...state, holes: action.payload };

    case bookingActionTypes.UPDATE_PLAYER_QUANTITY:
      return { ...state, playerQuantity: action.payload };

    case bookingActionTypes.UPDATE_CART_QUANTITY:
      return { ...state, cartQuantity: action.payload };

    case bookingActionTypes.UPDATE_CART:
      return { ...state, cart: action.payload };

    default:
      return state;
  }
}
