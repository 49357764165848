import React, { useEffect } from "react";
import { Route, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";

import ProtectedRoute from "routes/ProtectedRoute";
import Menu from "containers/menu";
import Wallet from "containers/wallet";
// import SecondaryMenu from "containers/secondaryMenu";
import Auth from "containers/auth";
import Dashboard from "containers/dashboard";
import BuyCoins from "containers/buyCoins";

// should be containers
import { Account } from "components/account";
import MyBookings from "components/myBookings";

import Cart from "containers/cart";
import Checkout from "containers/checkout";
import Order from "containers/order";

import Facility from "containers/teetime/facility";
import Index from "containers/teetime/index";
import Teetime from "containers/teetime/teetime";
import BookingReview from "containers/teetime/bookingReview";
import BookingConfirmation from "containers/teetime/bookingConfirmation";
import LandingContainer from "containers/landing";
import Reset from "components/auth/reset";

import Privacy from "components/landing/privacy";

import StripeWrapper from "components/stripeWrapper";

import Landing from "./landing";
import HowItWorks from "./howItWorks";
import Footer from "./ui/footer";

import { UserActive } from "api/rpc";
import { StatusCode } from "api/protocols";
import "public/scss/_global.scss";

export default function App(props: any) {
  const forceRefresh = props.authStore.refresh;

  useEffect(() => {
    void checkUserLogin();
  }, []);

  useEffect(() => {
    if (forceRefresh) {
      checkUserLogin();
      props.authActions.refreshUser(false);
    }
  });

  async function checkUserLogin() {
    const activeUserRes = await UserActive(true);
    if (activeUserRes.status === StatusCode.OK) {
      props.authActions.userLogin(activeUserRes.data.data);
    }
  }

  return (
    <BrowserRouter>
      <StripeWrapper>
        <Menu />
        {/* <SecondaryMenu /> */}
        <Switch>
          {/* Secure Views */}

          {/* <ProtectedRoute path="/account" component={Account} /> */}
          <Route path="/cart" component={Cart} />
          <Route path="/checkout" component={Checkout} />
          <Route path="/order/:orderId" component={Order} />
          <ProtectedRoute path="/facility/:facilityId" component={Facility} />
          <ProtectedRoute path="/facility" component={Index} />
          <ProtectedRoute path="/booking/:bookingToken" component={BookingReview} />
          <ProtectedRoute path="/confirmation/:confirmationToken" component={BookingConfirmation} />

          <Route path="/bookings" component={MyBookings} />
          <Route path="/wallet" component={Wallet} />
          <Route path="/password_reset" component={Reset} />

          <Route exact path="/" component={LandingContainer} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/tee-times" component={Landing} />
          <Route path="/auth/:type?" component={Auth} />
          <Route path="/how-it-works" component={HowItWorks} />
          <Route path="/buy-coins" component={BuyCoins} />
        </Switch>
        <Footer />
      </StripeWrapper>
    </BrowserRouter>
  );
}
