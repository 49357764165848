import React, { useEffect, useState } from "react";
import { GetOrder } from "api/rpc";
import { StatusCode } from "api/protocols";
import { useParams } from "react-router";
import SecondaryMenu from "components/ui/secondaryMenu";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { LocalStorage } from "api/localstorage";

import Cart from "components/ui/cart";
import "public/scss/checkout.scss";
import "public/scss/focusModal.scss";
import { CompleteCartButton } from "elements/cart/completeCartButton";

import "public/scss/order.scss";

interface IOrderedItems {
  quantity: number;
  price: number;
  product_title: string;
  variant_title: string;
}

interface IOrderTransactions {
  payment_redacted: string;
  amount: number;
  payment_brand: string;
}

interface IOrderTaxLine {
  title: string;
  rate: number;
  price: number;
}

interface IOrderState {
  orderNumber: number;
  lineItems: IOrderedItems[];
  transactions: IOrderTransactions[];
  tax_lines: IOrderTaxLine[];
  financial_status: string;
  subTotal: number;
  taxes: number;
  total: number;
}

export default function Order(props: any) {
  const { orderId } = useParams();
  const oOrderState: IOrderState = {
    orderNumber: 0,
    lineItems: [],
    transactions: [],
    tax_lines: [],
    financial_status: "",
    subTotal: 0,
    taxes: 0,
    total: 0,
  };

  const [orderState, setOrderState] = useState(oOrderState);

  useEffect(() => {
    GetOrderInfo().then(res => {
      console.log(res.data[0]);

      setOrderState({
        orderNumber: res.data[0].id,
        lineItems: res.data[0].line_items,
        transactions: res.data[0].transactions,
        tax_lines: res.data[0].tax_lines,
        financial_status: res.data[0].financial_status,
        subTotal: res.data[0].subtotal_price,
        taxes: res.data[0].total_taxes,
        total: res.data[0].total_price,
      });
    });
  }, []);

  async function GetOrderInfo() {
    const res = await GetOrder(orderId);
    if (res.status !== StatusCode.OK) {
      return null;
    }
    return res.data;
  }

  function goBack() {
    props.history.push("/buy-coins");
  }

  return (
    <>
      <SecondaryMenu
        title={'Checkout - <span style="color: #999">Order Confirmation</span>'}
        balance={props.authStore.user?.points?.balance}
        initials={props.authStore.user?.initials}
        previousAction={goBack}
        icon={["fal", "times"]}
      />
      <main className="order-main">
        <div className="wrapper">
          <div className="order-info-container">
            <h1>Order #{orderState.orderNumber}</h1>
            <p className="secondary-p">
              This receipt will be saved to your <a href="#">Order History</a>. <br></br>Your order is{" "}
              {orderState.financial_status}.
            </p>
            <p className="tertiary-p">Summary</p>
            <ul className="cart-items-container">
              {orderState.lineItems.map((item: IOrderedItems, index: number) => {
                return (
                  <li key={index} className="cart-item">
                    <div className="cart-item-content">
                      <p>
                        <span className="quantity">{item.quantity} x </span>
                        <span className="product_title">
                          {item.product_title}
                          {item.product_title !== item.variant_title && (
                            <span className="variant_title">{item.variant_title}</span>
                          )}
                        </span>
                      </p>
                      <p className="dashed-line">.</p>
                      <p>
                        <span>${item.price.toFixed(2)}</span>
                        <span className="paid">Paid</span>
                      </p>
                    </div>
                  </li>
                );
              })}
            </ul>
            <div className="payment-used-container">
              <p className="tertiary-p">Payment Info</p>
              {orderState.transactions.map((transaction: IOrderTransactions, index: number) => {
                return (
                  <div key={index} className="credit-card">
                    <span className="card-numbers">{transaction.payment_redacted}</span>
                    <span className="method-brand">{transaction.payment_brand.slice(0, 4)}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="wrapper">
          <section className="totals-section">
            <div className="titles-totals-container">
              <div className="titles-container">
                <p>Subtotal</p>
                {orderState.tax_lines.map((taxLine: IOrderTaxLine, index: number) => {
                  return <p key={index}>{taxLine.title}</p>;
                })}
                <p>Total</p>
              </div>
              <div className="totals-container">
                <p>${orderState.subTotal.toFixed(2)}</p>
                {orderState.tax_lines.map((taxLine: IOrderTaxLine, index: number) => {
                  return <p key={index}>${taxLine.price.toFixed(2)}</p>;
                })}
                <p>${orderState.total.toFixed(2)}</p>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
}
