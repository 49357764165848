import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import "public/scss/facility.scss";

interface IProps {
  image: any;
  longName: any;
  addressLine1: any;
  city: any;
  customerRating: any;

  key: any;
  id: any;
}

export const CourseCard: React.FC<IProps> = (props: any) => {
  const { image, longName, addressLine1, city, id, customerRating } = props;

  const history = useHistory();

  function navigateToFacility(facilityId: number) {
    console.log(facilityId);
    history.push(`/facility/${facilityId}`);
  }

  return (
    <button className="facility-card" onClick={() => navigateToFacility(id)}>
      <div className="facility-content">
        <div className="facility-image">
          <img src={image} alt={longName} />
        </div>
        <div className="facility-details">
          <p className="long-name">{longName}</p>
          <p className="location">
            {addressLine1}, {city}
          </p>
          <p className="rating">
            {(() => {
              //use an IIFE to do complex ops, then display the result
              const rating = customerRating;
              let count = 1;
              const arJSXRating = [];

              //Solid stars:
              for (; count <= rating && count <= 5; count++) {
                arJSXRating.push(<FontAwesomeIcon key={count} icon={["fas", "star"]} />);
              }
              //Partial stars:
              if (rating % 1 > 0.2) {
                arJSXRating.push(<FontAwesomeIcon key={count} icon={["fas", "star-half-alt"]} />);
                count++;
              }
              //Empty stars:
              while (count <= 5) {
                arJSXRating.push(<FontAwesomeIcon key={count} icon={["far", "star"]} />);
                count++;
              }

              return arJSXRating;
            })()}
          </p>
        </div>
        <div className="facility-price"></div>
      </div>
    </button>
  );
};
