import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { GetTeetimes, GetFacility, GetFacilities } from "api/rpc";
import { StatusCode } from "api/protocols";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LocalStorage } from "api/localstorage";
import GeoService, { IGeoDetails } from "elements/geo";
import { DateSelector } from "elements/dateSelector";
import { LocationSelector } from "elements/teetime/locationSelector";
import { CourseCard } from "elements/teetime/courseCard";
import "public/scss/teetimes.scss";
import "public/scss/facility.scss";
interface IFacilityList {
  id: number;
  long_name: string;
  address_line_1: string;
  city: string;
  customer_rating: number;
  default_image: {
    source: string;
  };
}
interface IFacilityListState {
  isListLoaded: boolean;
  coordinates: IGeoDetails;
  error: any;
  facilities: IFacilityList[];
}
export default function Facilities(props: any) {
  const [facilityState, setFacilityState] = useState<IFacilityListState>({
    isListLoaded: false,
    coordinates: null,
    error: null,
    facilities: [],
  });

  const [location, setLocation] = useState<string>("");

  async function fetchFacilities(geoDetails: IGeoDetails) {
    console.log(geoDetails);
    const param = "latitude=" + geoDetails.lat + "&longitude=" + geoDetails.lng;
    const res = await GetFacilities(param);
    if (res.status !== StatusCode.OK) {
      return;
    }
    console.log(res.data.data);
    setFacilityState(prev => ({
      ...prev,
      isListLoaded: true,
      facilities: res.data.data,
    }));
    setLocation(geoDetails.city);
  }

  useEffect(() => {}, []);
  function handleClick(value: any) {
    console.log(value);
  }

  function MapFacilityList(OFacility: IFacilityList, index: number) {
    return (
      <CourseCard
        key={index}
        id={OFacility.id}
        longName={OFacility.long_name}
        addressLine1={OFacility.address_line_1}
        city={OFacility.city}
        image={OFacility.default_image.source}
        customerRating={OFacility.customer_rating}
      />
    );
  }
  return (
    <section>
      <GeoService
        onLocationUpdate={geoDetails => {
          setFacilityState(cur => {
            const res = fetchFacilities(geoDetails);
            return { ...cur, coordinates: geoDetails };
          });
        }}
      />
      <div className="teetime-wrapper-2">
        <div className="teetime-list">
          {facilityState.isListLoaded
            ? facilityState.facilities.map((OFacilityItem: IFacilityList, i: number) =>
                MapFacilityList(OFacilityItem, i),
              )
            : null}
        </div>
      </div>
    </section>
  );
}
