import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Title, SubTitle } from "elements/typography/index";
import { Button } from "elements/button/index";
import { Input } from "elements/input/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "public/scss/auth.scss";

import { useLocation } from "react-router-dom";
import { resetPassword } from "api/rpc";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const Forgot: React.FC = (props: any) => {
  let query = useQuery();
  const code = query.get("code");
  const email = query.get("email");

  console.log(code, email);

  const [state, setState] = useState({
    email: "",
    code: "",
    password: "",
    password_confirm: "",
  });

  const canSubmit = state.password.length >= 1 && state.password_confirm.length >= 1;

  const handlePasswordChange = (value: any) => {
    const password = value;

    setState({
      ...state,
      password,
    });
  };

  const handlePasswordConfirmChange = (value: any) => {
    const password_confirm = value;

    setState({
      ...state,
      password_confirm,
    });
  };

  const handlePasswordReset = async () => {
    const password = state.password;
    const email = state.email;
    const code = state.code;

    try {
      await resetPassword(password, email, code);
    } catch (err) {
      console.log("err", err);
    }
  };

  // set url params to state
  useEffect(() => {
    let mounted = true;

    if (mounted) {
      setState({
        ...state,
        code,
        email,
      });
    }

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <main className="content">
      <div className="wrapper">
        <div className="content-container">
          <div className="landing-header landing-header-extended">
            {/* <div> instead of <form>, due to default event behaviour: */}
            <div className="landing-header-content login-form">
              <div className="ui-password_reset-group">
                <img className="ui-password_reset-logo" src="/public/images/clubhouse_logo_full.png" />

                <Title level={3} type={2} className="ui-password_reset-title">
                  Reset your password.
                </Title>
                <SubTitle className="ui-password_reset-subtitle">Reset the password for {}</SubTitle>

                <Input
                  className="ui-password_reset-input"
                  type="password"
                  placeholder="New Password"
                  onChange={handlePasswordChange}
                />

                <Input
                  className="ui-password_reset-input"
                  type="password"
                  placeholder="Confirm Password"
                  onChange={handlePasswordConfirmChange}
                />

                {/* <div>{state.status}</div> */}

                <Button
                  shape="rounded"
                  size="large"
                  block
                  type="primary"
                  onClick={handlePasswordReset}
                  disabled={!canSubmit}
                  className="ui-password_reset-button"
                >
                  Reset Password
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Forgot;
