import React, { useEffect, useState } from "react";
import classNames from "classnames";
import "elements/actionList/actionList.scss";

const List = (props: any) => (
  <ul className="ui-action-list_actions">
    {props.items.map((item: any, index: number) => (
      <li key={index}>
        <button
          onClick={() => item.onAction(item.value)}
          className={classNames("ui-action-list_item", {
            "ui-action-list_item--active": item.active,
          })}
        >
          <div className="ui-action-list_content">
            {item.icon ? <div className="ui-action-list_prefix">{item.icon}</div> : null}
            <div className="ui-action-list_text">
              <span>{item.content}</span>
              <p>{item.helpText}</p>
            </div>
            {item.suffix ? <div className="ui-action-list_suffix">{item.suffix}</div> : null}
          </div>
        </button>
      </li>
    ))}
  </ul>
);

const ActionList = (props: any) => (
  <div className="ui-action-list">
    <div
      className={classNames({
        "ui-action-list_section--withoutTitle": !props.sections,
      })}
    >
      {props.sections ? (
        <>
          {props.sections.map((section: any, index: number) => (
            <div key={index}>
              <p className="ui-action-list_title">{section.title}</p>
              <List items={section.items} />
            </div>
          ))}
        </>
      ) : (
        <List items={props.items} />
      )}
    </div>
  </div>
);

export default ActionList;
