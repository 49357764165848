import React, { useState } from "react";
import { InputField } from "elements/inputField";
import { UserRegister } from "api/rpc";
import { StatusCode } from "api/protocols";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

interface IRegisterProps {
  changeView: (registration: boolean) => void;
}

export interface IUserRegister {
  email: string;
  password: string;
  password_confirm: string;
  first_name: string;
  last_name: string;
  //TODO: fields beyond here are accepted by the API but not added to the db:
  addressLine1: string;
  addressLine2: string;
  addressCity: string;
  addressProvince: string;
  addressCountry: string;
  addressPostal: string;
  contact: string; //phone number
  subscribe: boolean;
}

export default function Register(props: IRegisterProps) {
  const history = useHistory();

  const [state, setState] = useState({
    first_name: "",
    last_name: "",
    addressLine1: "",
    addressLine2: "",
    addressCity: "",
    addressProvince: "",
    addressCountry: "",
    addressPostal: "",
    contact: "", //phone number
    email: "",
    password: "",
    password_confirm: "",
    subscribe: false,
    status: "",
  });

  function handleChange(event: any) {
    const { id, value } = event.target;
    setState((prevState) => ({ ...prevState, [id]: value }));
  }

  async function handleRegister() {
    const result = await UserRegister({ ...state });
    if (result.status !== StatusCode.OK) {
      setState({ ...state, status: "registration failed" });
      return;
    }

    setState({ ...state, status: "Registration successful! Redirecting to Login Page..." });

    history.push("/auth");

    // TODO: Maybe we can log user in here instead of redirecting
  }

  return (
    <div>
      <InputField id="email" type="email" placeholder="Email" onChange={handleChange} />
      <InputField id="password" type="password" placeholder="Password" onChange={handleChange} />
      <div id="registration-fields">
        <InputField id="password_confirm" type="password" placeholder="Confirm Password" onChange={handleChange} />
        <InputField id="first_name" type="text" placeholder="First Name" onChange={handleChange} />
        <InputField id="last_name" type="text" placeholder="Last Name" onChange={handleChange} />
        {/* Object.keys(oRegTextFields).map(Key => (
                  <InputField
                    id={Key}
                    key={Key}
                    type={Key === "contact" ? "tel" : "text"}
                    placeholder={Key === "contact" ? "Phone Number" : Capitalize(Key)}
                    onChange={handleChange}
                  />
                )) */}
        {/* <InputField
                  id="subscribe"
                  name="subscribe"
                  onChange={e => setState({ ...state, subscribe: e.target.checked })}
                  type="checkbox"
                />
                <label htmlFor="subscribe">Subscribe</label> */}
        <h6>**All fields are required.</h6>
        <div>{state.status}</div>
        <div className="ui-button-group ui-button-group-right-center">
          <button className="button-primary" onClick={handleRegister}>
            Register
          </button>
        </div>

        <div className="text-center new-account">
          <Link to="/auth">Login into Existing Account</Link>
        </div>
      </div>
    </div>
  );
}
