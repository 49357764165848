import { LocalStorage } from "api/localstorage";
import { ITeetimeState } from "reducers/teetime";
import { StatusCode } from "api/protocols";

export function updateDateFilter(date: string) {
  return (dispatch: any) => {
    dispatch({
      type: "update.filter",
      payload: {
        date,
      },
    });
  };
}

export function updateTimeFilter(time: number) {
  return (dispatch: any) => {
    dispatch({
      type: "update.filter",
      payload: {
        time,
      },
    });
  };
}

export function updatePointsFilter(points: number) {
  return (dispatch: any) => {
    dispatch({
      type: "update.filter",
      payload: {
        points,
      },
    });
  };
}

export function updateHolesFilter(holes: number) {
  return (dispatch: any) => {
    dispatch({
      type: "update.filter",
      payload: {
        holes,
      },
    });
  };
}

export function updatePlayersFilter(players: number) {
  return (dispatch: any) => {
    dispatch({
      type: "update.filter",
      payload: {
        players,
      },
    });
  };
}

export function updateCartFilter(cart: boolean) {
  return (dispatch: any) => {
    dispatch({
      type: "update.filter",
      payload: {
        cart,
      },
    });
  };
}
