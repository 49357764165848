import { actionTypes } from "../reducers/landing";
import { clubhouse } from "../api/rpc";

const retrieveQuerySuccess = (query: any) => {
  return {
    type: actionTypes.RETRIEVE_FACILITIES_SUCCESS,
    payload: query,
  };
};

const retrieveQueryError = (error: any) => {
  console.log("Error retrieving facilities", error);
  return {
    type: actionTypes.RETRIEVE_FACILITIES_ERROR,
  };
};

export const retrieveQuery = async (dispatch: any, query?: any) => {
  try {
    const res = await clubhouse.facilities.query(query);

    const filteredRes: object[] = [];

    res.data.data.forEach((result: any) => {
      const firstCharQuery = query.charAt(0).toLowerCase();
      const firstCharResult = result.long_name.charAt(0).toLowerCase();

      if (firstCharQuery === firstCharResult) {
        filteredRes.push(result);
      } else {
        return;
      }
    });

    dispatch(retrieveQuerySuccess(filteredRes));
  } catch (error) {
    dispatch(retrieveQueryError(error));
  }
};
