import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { FilterRadio } from "elements/teetime/filterRadio";
import Modal from "react-modal";
import { Radio } from "elements/radio/index";
import { Title } from "elements/typography/index";

import "public/scss/teetimes.scss";

interface IProps {
  key?: any;
  date?: string;
  time?: number;
  holes?: number;
  originalPrice?: number;
  price?: number;
  nineCode?: string;
  cartAvailable?: boolean | null;
  spacesAvailable?: number;
  allowedGroupSizes?: string[];
  selected?: boolean | undefined;
  onClick?: (index: any, event: any) => void;
  bookingCart?: boolean;
  checkout?: (date: string, time: string, nineCode: string, holes: number) => Promise<void>;
  onChange?: (value: any) => void;
  teetimeStore: any;
}

export const TeeTimeCard: React.FC<IProps> = (props: any) => {
  const history = useHistory();

  const {
    date,
    time,
    holes,
    originalPrice,
    price,
    nineCode,
    cartAvailable,
    spacesAvailable,
    allowedGroupSizes,
    selected,
    onClick,
    bookingCart,
    checkout,
    onChange,
    teetimeStore,
    ...rest
  } = props;

  const [modalIsOpen, setIsOpen] = useState<boolean>(false);

  // sets default selections manually or through variables
  const [bookingData, setBookingData] = useState({
    holes: teetimeStore.holes,
    players: teetimeStore.players,
    cart: teetimeStore.cart,
    cartQuantity: 0,
  });

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function convertTime(time: any) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }

    return time.join(""); // return adjusted time or original string
  }

  function convertClock(time: any) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time = time[0] < 12 ? "AM" : "PM"; // Set AM/PM
    }

    return time;
  }

  // Display slots that are still available
  const available = Array.from(Array(spacesAvailable).keys());
  const availableSpots = available.map((number, index) => <FontAwesomeIcon key={index} icon={["fas", "circle"]} />);

  // Display spots that have already been booked
  const bookedSpots: any = null;
  if (spacesAvailable < 4) {
    const booked = Array.from(Array(4 - spacesAvailable).keys());
    const bookedSpots = booked.map((number, index) => <FontAwesomeIcon key={index} icon={["far", "circle"]} />);
  }

  Modal.setAppElement("#root");

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      border: "none",
      borderRadius: "10px",
      maxWidth: "400px",
    },
    overlay: {
      backgroundColor: "rgba(0,0,0,.4)",
    },
  };

  function continueWithBooking(date: string, time: string, requestCart: boolean, nineCode: string, holes: number) {
    if (requestCart) {
      openModal();
      return;
    }

    checkout(date, time, nineCode, holes);
  }

  const handleHolesChange = (value: number) => {
    const holes = value;

    setBookingData({
      ...bookingData,
      holes,
    });
  };

  const handlePlayersChange = (value: number) => {
    const players = value;
    setBookingData({
      ...bookingData,
      players,
    });
  };

  const handleCartChange = (value: boolean) => {
    const cart = value;
    setBookingData({
      ...bookingData,
      cart,
    });
  };

  const handleCartQuantityChange = (value: number) => {
    const cartQuantity = value;
    setBookingData({
      ...bookingData,
      cartQuantity,
    });
  };

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      if (onChange) {
        onChange(bookingData);
      }
    }

    return () => {
      mounted = false;
    };
  }, [bookingData.holes, bookingData.players, bookingData.cart, bookingData.cartQuantity]);

  const allowedSizes = [
    { disabled: allowedGroupSizes.includes("1") ? false : true },
    { disabled: allowedGroupSizes.includes("2") ? false : true },
    { disabled: allowedGroupSizes.includes("3") ? false : true },
    { disabled: allowedGroupSizes.includes("4") ? false : true },
  ];

  return (
    <React.Fragment {...rest}>
      <div className="teetime-card" onClick={() => onClick(time, event)}>
        <div className="teetime-content">
          <div className="teetime-time">
            <p className="starttime">{convertTime(time)}</p>
            <p className="clocktime">{convertClock(time)}</p>
          </div>
          <div className="teetime-details">
            <p className="holes">{holes} Holes</p>
            <p className="spots">
              <span className="quantity-available">
                {availableSpots}
                {bookedSpots ? bookedSpots : null}
              </span>
              <span className="add-ons">{cartAvailable ? <span>Cart or Walking</span> : <span>Walking</span>}</span>
            </p>
          </div>
          <div className="teetime-price">
            {originalPrice !== price ? (
              <div className="price-content original-price-content">
                <span className="price">{originalPrice}</span>
                <FontAwesomeIcon icon={["fas", "coins"]} className="coins-icon" />
              </div>
            ) : null}
            <div className="price-content">
              {originalPrice !== price ? (
                <FontAwesomeIcon className="hot-deal-icon" icon={["fad", "fire-alt"]} />
              ) : null}
              <span className="price">{price}</span>
              <FontAwesomeIcon icon={["fas", "coins"]} className="coins-icon" />
            </div>
          </div>
        </div>
        {selected ? (
          <div>
            <hr />
            <div className="booking-options">
              <div className="booking-golfers-filter-container">
                <Title level={3} type={1} className="booking-holes-title">
                  Number of Golfers
                </Title>
                <Radio.Group
                  type={2}
                  name="bookingGolfers"
                  defaultValue={bookingData.players}
                  onChange={handlePlayersChange}
                >
                  {allowedSizes.map((allowedSize: any, i: number) => {
                    const disabled = allowedSize.disabled;

                    return (
                      <Radio.Button key={i} disabled={disabled} name="bookingGolfers" value={i + 1}>
                        {i + 1}
                      </Radio.Button>
                    );
                  })}
                </Radio.Group>
              </div>

              <div className="filter-section filter-inline">
                <div className="booking-holes-filter-container">
                  <Title level={3} type={1} className="booking-holes-title">
                    Holes
                  </Title>
                  <Radio.Group
                    type={2}
                    name="bookingHoles"
                    defaultValue={bookingData.holes}
                    onChange={handleHolesChange}
                  >
                    <Radio.Button name="bookingHoles" value={18}>
                      18 Holes
                    </Radio.Button>
                    <Radio.Button name="bookingHoles" value={9}>
                      9 Holes
                    </Radio.Button>
                  </Radio.Group>
                </div>

                {cartAvailable && (
                  <div className="booking-cart-filter-container">
                    <Title level={3} type={1} className="booking-cart-title">
                      Cart
                    </Title>
                    <Radio.Group
                      type={2}
                      name="bookingCart"
                      defaultValue={bookingData.cart}
                      onChange={handleCartChange}
                    >
                      <Radio.Button name="bookingCart" value={true}>
                        Yes
                      </Radio.Button>
                      <Radio.Button name="bookingCart" value={false}>
                        No
                      </Radio.Button>
                    </Radio.Group>
                  </div>
                )}
              </div>
            </div>
            <div className="button-group button-group-right">
              <button className="button cancel" onClick={() => onClick(time, event)}>
                Cancel
              </button>
              <button
                onClick={() => continueWithBooking(date, time, bookingCart, nineCode, holes)}
                className="button-primary"
              >
                Checkout <FontAwesomeIcon icon={["far", "arrow-right"]} />
              </button>
            </div>
          </div>
        ) : null}
      </div>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles}>
        <button onClick={closeModal} className="exit" aria-label="Close Modal" title="Close modal">
          <FontAwesomeIcon icon={["fal", "times"]} />
        </button>
        <div className="modal-container">
          <h1>Select Carts</h1>
          <Title level={3} type={1} className="booking-numberOfCarts-title">
            Number of riders
          </Title>
          <Radio.Group
            type={2}
            name="booking-number-of-carts"
            className="booking-number-of-carts"
            defaultValue={2}
            onChange={handleCartQuantityChange}
          >
            {allowedSizes.map((allowedSize: any, i: number) => {
              const numberOfCarts = i + 1;
              const players = bookingData.players;

              if (numberOfCarts <= players) {
                return (
                  <Radio.Button key={i} name="booking-number-of-cart" value={numberOfCarts}>
                    {numberOfCarts}
                  </Radio.Button>
                );
              }
            })}
          </Radio.Group>

          <div className="button-group button-group-right">
            <button className="button" onClick={() => closeModal()}>
              Cancel
            </button>
            <button className="button-primary" onClick={() => continueWithBooking(date, time, false, nineCode, holes)}>
              Checkout <FontAwesomeIcon icon={["far", "arrow-right"]} />
            </button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};
