import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as landingActions from "actions/landing";
import Landing from "components/landing/index";

const mapStateToProps = (state: any) => {
  return {
    landingStore: { ...state.landingStore },
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    landingActions: bindActionCreators(
      {
        ...landingActions,
      },
      dispatch,
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
