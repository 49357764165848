import React from "react";
import classNames from "classnames";
import "./style.scss";

interface IProps {
  prefixCls?: string;
  className?: string;
  icon?: React.ReactNode;
}

export const Tag: React.FC<IProps> = (props: any) => {
  const { prefixCls = "rc-tag", className, icon, children } = props;

  const tagClassName = classNames(prefixCls, className);

  const iconNode = icon || null;

  return (
    <div className={tagClassName}>
      {iconNode}
      <span>{children}</span>
    </div>
  );
};

export default Tag;
