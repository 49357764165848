import React from "react";

import { Alert, Button, Col, Form, Jumbotron, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EmailSignUp from "components/landing/emailSignUp";

import "public/scss/landing.scss";

export default function HowItWorks() {
  return (
    <main className="content">
      <div className="wrapper">
        <div className="content-container">
          <div className="landing-header landing-header-extended">
            <div className="landing-header-content landing-header-content-flex">
              <div>
                <h1 className="heading-left">
                  Golf more, <br /> Pay less.
                </h1>
                <p>Take control of your golf savings with all new Clubhouse Coins — purchase, earn, and redeem.</p>

                <div className="ui-button-group">
                  <button className="button-secondary">Learn More</button>
                  <a href="/" className="button-primary">
                    View tee times{" "}
                    <FontAwesomeIcon icon={["fal", "arrow-right"]} className="ui-button-icon ui-button-icon-right" />
                  </a>
                </div>
              </div>

              <img src="https://clubhousegolf.s3-us-west-2.amazonaws.com/www.clubhousegolf.ca/marketplace/landing/landing_course_image.png" />
            </div>
          </div>

          <section className="how-it-works-section">
            <div className="wrapper">
              <h5>How It Works</h5>
              <h2>What are Clubhouse Coins?</h2>
              <p>
                The all new digital currency used to maximize your golf experience.<br></br>Clubhouse Coins is the
                premium way to pay for your next golf round.
              </p>
              <div className="three-icon-container">
                <div className="icon-container">
                  <div className="svg-container">
                    <FontAwesomeIcon icon={["fal", "coin"]} />
                  </div>
                  <h5>Purchase</h5>
                  <p>Choose a package that suits your golfing needs or trial the program with a one-time purchase.</p>
                </div>
                <div className="icon-container">
                  <div className="svg-container">
                    <FontAwesomeIcon icon={["fal", "coins"]} />
                  </div>
                  <h5>Redeem</h5>
                  <p>Choose where and when you want to play. Redeem your coins for limitless golf deals.</p>
                </div>
                <div className="icon-container">
                  <div className="svg-container">
                    <FontAwesomeIcon icon={["fal", "golf-ball"]} />
                  </div>
                  <h5>Play</h5>
                  <p>No payment required at the pro shop, just present your confirmation for seamless check in.</p>
                </div>
              </div>
              <a href="/buy-coins" className="button-primary">
                Purchase Coins <FontAwesomeIcon icon={["fal", "arrow-right"]} />
              </a>
            </div>
          </section>
        </div>

        <div className="content-section">
          <div className="landing-section landing-section-50-split">
            <div className="landing-section-50">
              <h2 className="section-title section-title-left">Instant Redemption.</h2>
              <h2 className="section-title section-title-left section-title-primary">Bonus Rewards.</h2>
              <h2 className="section-title section-title-left">Exclusive Savings.</h2>

              <p className="section-description-title section-description-title-left">The Clubhouse Golf Mobile App</p>
              <p className="section-description section-description-left">
                Whether you are topping up your balance, or looking for your next round, you can do it all from the
                Clubhouse Golf mobile app.
              </p>

              <div className="app-store-icons">
                <img src="/public/images/badges/app-store-badge-white.png" />
                <img src="/public/images/badges/google-play-badge.png" />
              </div>
            </div>

            <div className="landing-section-50">
              <img src="/public/images/landing_app_mockup.png" className="app-screen-mockup" />
            </div>
          </div>
        </div>
      </div>

      <div className="content-section content-section-primary">
        <div className="landing-section">
          <div className="course-clients">
            <div className="course-client_container">
              <img src="/public/images/course-logos/grey_silo_logo.png" />
            </div>
            <div className="course-client_container">
              <img src="/public/images/course-logos/highlands_links_logo.png" />
            </div>
            <div className="course-client_container">
              <img src="/public/images/course-logos/loch_march_logo.png" />
            </div>
            <div className="course-client_container">
              <img src="/public/images/course-logos/salmon_arm_logo.png" />
            </div>
            <div className="course-client_container">
              <img src="/public/images/course-logos/the_ridge_logo.png" />
            </div>
          </div>
          <h2 className="section-title section-title-center">Access to some of the best courses in Canada.</h2>
          <p className="section-description section-description-center">
            Clubhouse Golf prides itself on building relationships with customers and courses from coast to coast.
          </p>

          <div className="section-action">
            <a href="/" className="button-secondary">
              View tee times
              <FontAwesomeIcon icon={["fal", "arrow-right"]} className="ui-button-icon ui-button-icon-right" />
            </a>
          </div>
        </div>
      </div>

      <EmailSignUp />
    </main>
  );
}
