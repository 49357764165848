import VError from "verror";

const isLocalStorageAvailable = browserSupportTest();

// optionally accepting a ttl (time to live) in milliseconds
export const LocalStorage = {
  set (key: string, value: any, ttl?: number): Error | null {
    if (!isLocalStorageAvailable) {
      return new VError("localstorage not available on this browser");
    }
    const store = {
      label: "clubhousegolf",
      key,
      value,
      createdAt: Date.now(),
      expiredAt: ttl ? Date.now() + ttl : -1,
    };
    try {
      window.localStorage.setItem(key, JSON.stringify(store));
      return null;
    } catch (e) {
      return new VError(e, "failed to stringify js object");
    }
  },
  get (key: string, defaultValue: any = undefined, silent = true): Error | any {
    if (!isLocalStorageAvailable) {
      return new VError("localstorage not available on this browser");
    }
    if (!localStorage.getItem(key)) {
      return defaultValue;
    }
    const serializedStore = localStorage.getItem(key);
    try {
      const parsedStore = JSON.parse(serializedStore);
      const now = Date.now();
      if (
        !parsedStore.label ||
        parsedStore.label !== "clubhousegolf" ||
        (parsedStore.expiredAt !== -1 && now >= parsedStore.expiredAt)
      ) {
        if (now >= parsedStore.expiredAt) {
          this.remove(key);
        }
        return defaultValue;
      }
      return parsedStore.value;
    } catch (e) {
      return new VError(e, "failed to stringify js object");
    }
  },
  remove (key: string): Error | null {
    if (!isLocalStorageAvailable) {
      return new VError("localstorage not available on this browser");
    }
    localStorage.removeItem(key);
    return null;
  },
};

function browserSupportTest() {
  const test = "test";
  try {
    window.localStorage.setItem(test, test);
    window.localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}
