import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as AuthActions from "actions/auth";
import * as UIActions from "actions/ui";
import * as CartActions from "actions/cart";
import * as CheckoutActions from "actions/checkout";

import Cart from "components/ui/cart";

const mapStateToProps = (state: any) => {
  return {
    authStore: { ...state.authStore },
    cartStore: { ...state.cartStore },
    checkoutStore: { ...state.checkoutStore },
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    authActions: bindActionCreators(
      {
        ...AuthActions,
      },
      dispatch,
    ),
    uiActions: bindActionCreators(
      {
        ...UIActions,
      },
      dispatch,
    ),
    cartActions: bindActionCreators(
      {
        ...CartActions,
      },
      dispatch,
    ),
    checkoutActions: bindActionCreators(
      {
        ...CheckoutActions,
      },
      dispatch,
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
