export function enqueue() {
  return (dispatch: any, getState: any) => {
    const queueCount = getState().uiStore.queue;
    if (queueCount == 0) {
      dispatch({ type: "loading", payload: true });
    }
    dispatch({ type: "modify.queue", payload: queueCount + 1 });
  };
}

export function dequeue() {
  return (dispatch: any, getState: any) => {
    const queueCount = getState().uiStore.queue;
    if (queueCount == 1) {
      dispatch({ type: "loading", payload: false });
    }
    dispatch({ type: "modify.queue", payload: queueCount - 1 });
  };
}

export function showError(errorMessage: string) {
  return (dispatch: any) => {
    dispatch({ type: "error.show", payload: { errorMessage } });
  };
}

export function closeError() {
  return (dispatch: any) => {
    dispatch({ type: "error.close" });
  };
}
