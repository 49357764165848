import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Title, SubTitle } from "elements/typography/index";
import { Button } from "elements/button/index";
import { Input } from "elements/input/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "public/scss/auth.scss";
import { sendResetLink } from "api/rpc";

export const Forgot: React.FC = () => {
  const [state, setState] = useState({
    email: "",
    status: "",
  });

  const canSubmit = state.email.length >= 1;

  const handleEmailChange = (value: any) => {
    const email = value;

    setState({
      ...state,
      email,
    });
  };

  const handleSendEmail = async () => {
    console.log(state.email);

    try {
      const res = sendResetLink(state.email);
      console.log(res);
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <div className="ui-reset-group">
      <img className="ui-reset-logo" src="/public/images/clubhouse_logo_full.png" />

      <Title level={3} type={2} className="ui-reset-title">
        Forgot your password?
      </Title>
      <SubTitle className="ui-reset-subtitle">We'll send you an email to reset it</SubTitle>

      <Input type="email" placeholder="Email Address" onChange={handleEmailChange} />

      <div>{state.status}</div>

      <Button
        shape="rounded"
        size="large"
        block
        type="primary"
        onClick={handleSendEmail}
        disabled={!canSubmit}
        className="ui-reset-button"
      >
        Send Email
      </Button>

      <div className="ui-reset-secondary-buttons">
        <Link to="/auth">
          <FontAwesomeIcon icon={["fas", "chevron-left"]} /> Back to Login
        </Link>
      </div>
    </div>
  );
};

export default Forgot;
