import { connect } from "react-redux";
import Errorbox from "components/ui/errorbox";
import { bindActionCreators } from "redux";
import * as UIActions from "actions/ui";

const mapStateToProps = (state: any) => {
  return {
    ...state.UIReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    uiActions: bindActionCreators(
      {
        ...UIActions,
      },
      dispatch,
    ),
  };
};

export default connect<any>(mapStateToProps, mapDispatchToProps)(Errorbox);
