import React, { useEffect, useState } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";

import { bindActionCreators } from "redux";
import * as AuthActions from "../actions/auth";
import * as UIActions from "../actions/ui";
import { connect } from "react-redux";

function ProtectedRoute({ component: Component, ...props }: any) {
  return <Route {...props}>{props.authStore.user ? <Component /> : <Redirect to={"/"} />}</Route>;
}

//
// const mapDispatchToProps = (dispatch: any) => {
//   return {
//     authActions: bindActionCreators(
//       {
//         ...AuthActions,
//       },
//       dispatch,
//     ),
//     uiActions: bindActionCreators(
//       {
//         ...UIActions,
//       },
//       dispatch,
//     ),
//   };
// };

const mapStateToProps = (state: any) => {
  const { authStore } = state;
  return {
    authStore: { ...authStore },
  };
};

export default connect(mapStateToProps)(ProtectedRoute);
