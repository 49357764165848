import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { GetTeetimes, GetFacility, PostBooking, PutBooking, clubhouse } from "api/rpc";
import { StatusCode, APIGet } from "api/protocols";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LocalStorage } from "api/localstorage";
import { TeeTimeCard } from "elements/teetime/teetimeCard";
import { FilterRadio } from "elements/teetime/filterRadio";
import { DateSelector } from "elements/dateSelector";
import { FilterSlider } from "elements/teetime/filterSlider";
import { Divider } from "elements/divider/index";

import { useParams } from "react-router";

import "public/scss/teetimes.scss";
import moment from "moment";

interface ITeetimeList {
  id: number;
  date: string;
  time: number;
  holes: number;
  nineCode: string;
  original_price_coins: number;
  price_coins: number;
  spacesAvailable: number;
  selected: boolean;
  allowedGroupSizes: [];
  power_cart: any;
}

interface ITeetimeListState {
  isListLoaded: boolean;
  error: any;
  teetimes: ITeetimeList[];
  facility: any;
  selectedTeetime: number;
}

interface IProps {
  authActions: {};
  authStore: {};
  bookingActions: {};
  bookingStore: {};
  teetimeActions: {};
  teetimeStore: {};
}

interface IParams {
  facilityId: any;
}

export const Facility: React.FC<IProps> = (props: any) => {
  const { authActions, authStore, bookingActions, bookingStore, teetimeActions, teetimeStore } = props;

  const history = useHistory();
  const { facilityId } = useParams<IParams>();

  const [loading, setLoading] = useState<boolean>(false);

  const [teetimeState, setTeetimeState] = useState<ITeetimeListState>({
    isListLoaded: false,
    error: null,
    teetimes: [],
    facility: null,
    selectedTeetime: null,
  });

  async function GetTeetimeList(holesSelected?: number, start_date?: string) {
    const facility_id = facilityId;

    // how to call holes first, holesSelected once changed
    const holes = teetimeStore.holes;
    const players = teetimeStore.players;
    // const start_time = teetimeStore.timeFilter;
    // const cart = teetimeStore.cartFilter;
    const res = await GetTeetimes(facility_id, holes, start_date, players);
    if (res.status !== StatusCode.OK) {
      return;
    }
    setTeetimeState(prev => ({
      ...prev,
      isListLoaded: true,
      teetimes: res.data.data,
    }));
    setLoading(false);
  }

  const GetFacilityObject = async () => {
    try {
      const res = await GetFacility("id=" + facilityId, true);
      // if (res.status !== StatusCode.OK) {
      //   return;
      // }

      setTeetimeState(prev => ({
        ...prev,
        facility: res.data.data[0],
      }));

      return res;
    } catch (e) {
      console.log(e);
    }
  };

  async function navigateToBooking(date: string, time: string, nineCode: string, holes: number) {
    // Need to create booking with params

    const res = await PostBooking(facilityId, date, time, nineCode, holes);

    if (res.status !== StatusCode.OK) {
      return;
    }

    // Set booking id in local storage
    LocalStorage.set("booking_token", res.data.data.token);

    // Update the booking with the inputs selected by the user
    // number of golfers, number of holes
    const booking = await PutBooking(
      res.data.data.token,
      bookingStore.holes,
      bookingStore.playerQuantity,
      bookingStore.cartQuantity,
    );
    if (res.status !== StatusCode.OK) {
      return;
    }

    // navigate to the booking page to review their booking
    history.push(`/booking/${LocalStorage.get("booking_token")}`);
  }

  useEffect(() => {
    // props.teetimeStore.holesFilter?
    void GetTeetimeList(teetimeStore.holes, teetimeStore.date);
    void GetFacilityObject();
    // GetTeetimeList(teetimeStore.holesFilter, new Date(value).toLocaleDateString());
  }, []);

  const holesFilterOptions = [
    { name: "18 Holes", value: 18, selected: teetimeStore.holes == 18 ? true : false },
    { name: "9 Holes", value: 9, selected: teetimeStore.holes == 9 ? true : false },
  ];

  const playerFilterOptions = [
    { name: "1", value: 1, selected: teetimeStore.players == 1 ? true : false },
    { name: "2", value: 2, selected: teetimeStore.players == 2 ? true : false },
    { name: "3", value: 3, selected: teetimeStore.players == 3 ? true : false },
    { name: "4", value: 4, selected: teetimeStore.players == 4 ? true : false },
  ];

  const cartFilterOptions = [
    { name: "Yes", value: true, selected: teetimeStore.cart == true ? true : false },
    { name: "No", value: false, selected: teetimeStore.cart == false ? true : false },
  ];

  // teetime-card

  // handleTeeTimeSelection
  function selectTeetime(index: any, event: any) {
    // if it is the cancel button
    if (event.target.classList.contains("cancel")) {
      setTeetimeState(prev => ({
        ...prev,
        selectedTeetime: null,
      }));
    } else {
      setTeetimeState(prev => ({
        ...prev,
        selectedTeetime: index,
      }));
    }
  }

  const handleDateChange = (value: any) => {
    setLoading(true);

    const year = value.getFullYear();
    const month = value.getMonth() + 1;
    const dateSlice = value.getDate();

    const date = `${year}-0${month}-${dateSlice}`;

    teetimeActions.updateDateFilter(date);
    GetTeetimeList(teetimeStore.holes, date);
  };

  const handleTimeChange = (value: any) => {
    setLoading(true);

    const min = value.value.min;
    const max = value.value.max;

    const time = { min, max };

    teetimeActions.updateTimeFilter(time);
  };

  const handlePointsChange = (value: any) => {
    setLoading(true);

    const min = value.value.min;
    const max = value.value.max;

    const points = { min, max };

    teetimeActions.updatePointsFilter(points);
  };

  const handleHolesChange = (value: number) => {
    setLoading(true);

    const holes = value;

    teetimeActions.updateHolesFilter(holes);
  };

  const handlePlayersChange = (value: number) => {
    setLoading(true);

    const players = value;

    teetimeActions.updatePlayersFilter(players);
  };

  const handleCartChange = (value: boolean) => {
    setLoading(true);

    const cart = value;

    teetimeActions.updateCartFilter(cart);
  };

  const [init, setInit] = useState(false);

  useEffect(() => {
    let mounted = true;

    // GetTeetimeList(teetimeStore.holesFilter, teetimeStore.date);

    // set default filter selection manually or through variables
    if (mounted && !init) {
      const error = teetimeState.facility === null;
      if (!error) {
        const defaultHoles = teetimeState.facility.default_holes_filter;

        teetimeActions.updateHolesFilter(defaultHoles);
        teetimeActions.updatePlayersFilter(2);
        teetimeActions.updateCartFilter(false);

        setInit(true);
      }
    }

    return () => {
      mounted = false;
    };
  });

  const [teeTimes, setTeeTimes] = useState([]);

  useEffect(() => {
    let mounted = true;

    const getData = async () => {
      const facility_id = facilityId;
      const holes = teetimeStore.holes;
      const startDate = teetimeStore.date;
      const startTime = moment(teetimeStore.time.min, "LT").format("HH:mm");
      const players = teetimeStore.players;
      const points = teetimeStore.points.min;
      const endTime = moment(teetimeStore.time.max, "LT").format("HH:mm");

      const res = await clubhouse.teetimes.retrieve(facility_id, holes, startDate, startTime, players, points, endTime);

      const teeTimes = res.data.data;

      if (mounted) {
        setTeeTimes(teeTimes);
        setLoading(false);
      }
    };

    if (init) {
      // get tee time list
      getData();
    }

    return () => {
      mounted = false;
    };
  }, [teetimeStore]);

  const handleTeeTimeCardChange = (value: any) => {
    bookingActions.updateHoles(value.holes);
    bookingActions.updatePlayerQuantity(value.players);
    bookingActions.updateCartQuantity(value.cartQuantity);
    bookingActions.updateCart(value.cart);
  };

  return (
    <main>
      <div className="wrapper">
        <section>
          <div className="sub-navigation">
            <a href="/facility">
              <FontAwesomeIcon className="map-icon" icon={["far", "arrow-left"]} />
              View Courses
            </a>
          </div>
        </section>
        <section className="facility-intro">
          <div className="course-header">
            <div className="course-details">
              <div className="facility-stats-image-group">
                <p className="long-name">{teetimeState.facility?.long_name}</p>
                <p className="course-location">
                  <FontAwesomeIcon className="map-icon" icon={["fas", "map-marker-alt"]} />
                  <span>
                    {teetimeState.facility?.address_line_1}, {teetimeState.facility?.city}{" "}
                  </span>
                  <span className="distance">— {teetimeState.facility?.distance}</span>
                </p>
                <hr className="facility-page-divider" />

                <ul className="facility-stats">
                  <li>
                    <span className="text-subdued">Par:</span> {teetimeState.facility?.default_par}
                  </li>
                  <li>
                    <span className="text-subdued">Rating:</span> {teetimeState.facility?.default_rating}
                  </li>
                  <li>
                    <span className="text-subdued">Yards:</span> {teetimeState.facility?.default_yards}
                  </li>
                  <li>
                    <span className="text-subdued">Slope:</span> {teetimeState.facility?.default_slope}
                  </li>
                </ul>
              </div>

              <div className="course-image">
                <img src={teetimeState.facility?.default_image.source} alt={teetimeState.facility?.long_name} />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="teetime-wrapper">
            <div className="teetime-filters">
              <DateSelector onChange={handleDateChange} />

              <FilterSlider
                title="Time"
                max={22}
                min={4}
                step={1}
                minValue={6} // defaults / current
                maxValue={20} // defaults / current
                onChange={handleTimeChange}
              />
              <FilterSlider
                title="Points"
                max={500}
                min={100}
                step={25}
                minValue={175}
                maxValue={375}
                onChange={handlePointsChange}
              />
              <FilterRadio
                id="holesFilter"
                title="Holes"
                options={holesFilterOptions}
                selected={teetimeStore.holes != undefined && teetimeStore.holes}
                onChange={handleHolesChange}
              />
              <FilterRadio
                id="playersFilter"
                title="Number of Golfers"
                options={playerFilterOptions}
                selected={teetimeStore.players}
                onChange={handlePlayersChange}
              />
              <FilterRadio
                id="cartFilter"
                title="Cart"
                options={cartFilterOptions}
                selected={teetimeStore.cart}
                onChange={handleCartChange}
              />
            </div>
            <div className="teetime-list">
              {loading ? (
                <div className="loading-container">
                  <img src={"/public/images/loader.svg"} alt="A green semi-circle spinning" />
                  <p>Loading...</p>
                </div>
              ) : teetimeState.isListLoaded ? (
                <>
                  {teeTimes.length > 0 ? (
                    teeTimes.map((teeTime: ITeetimeList, i: number) => {
                      return (
                        <TeeTimeCard
                          key={i}
                          date={teeTime.date}
                          time={teeTime.time}
                          holes={teeTime.holes}
                          originalPrice={teeTime.original_price_coins}
                          price={teeTime.price_coins}
                          nineCode={teeTime.nineCode}
                          cartAvailable={teeTime.power_cart ? true : false}
                          spacesAvailable={teeTime.spacesAvailable}
                          allowedGroupSizes={teeTime.allowedGroupSizes}
                          selected={teetimeState.selectedTeetime == teeTime.time ? true : false}
                          onClick={selectTeetime}
                          bookingCart={bookingStore.cart}
                          checkout={navigateToBooking}
                          onChange={handleTeeTimeCardChange}
                          teetimeStore={teetimeStore}
                        />
                      );
                    })
                  ) : (
                    <p>No tee times available</p>
                  )}
                </>
              ) : null}
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};

export default Facility;
