import React, { useEffect, useState, useCallback, useRef } from "react";
import { GetPaymentMethodSetupIntent, SavePaymentMethod } from "api/rpc";
import { Button } from "elements/button/index";
import {
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownOption } from "elements/dropdown";
import ActionList from "elements/actionList/ActionList";
import Popover from "elements/popover/Popover";
import Modal from "react-modal";

import useOutsideAlerter from "hooks/useOutsideAlerter/useOutsideAlerter";

import "public/scss/paymentMethod.scss";

const CARD_OPTIONS = {
  style: {
    base: {
      color: "#000000",
      fontFamily: '"Avenir Next", "Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      fontWeight: "600",
      letterSpacing: "0.52",
      "::placeholder": {
        color: "#e7e7e7",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
    complete: {
      color: "#000000",
    },
    empty: {},
  },
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    borderRadius: "10px",
    maxWidth: "400px",
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,.4)",
  },
};

export default function PaymentMethods(props: any) {
  const stripe = useStripe();
  const elements = useElements();
  const [state, setState] = useState({
    status: null,
    saveCard: false,
  });
  const [loading, setLoading] = useState(false);

  // modal settings
  const [modalIsOpen, setIsOpen] = useState<boolean>(false);

  Modal.setAppElement("#root");

  function openModal() {
    setIsOpen(true);
    setState({
      ...state,
      saveCard: false,
    });
  }

  function closeModal() {
    setIsOpen(false);
    setState({
      ...state,
    });
  }

  const handleChange = (event: any) => {
    if (event.error) {
      setState({
        ...state,
        status: event.error.message,
      });
      return;
    }
    setState({
      ...state,
      status: null,
      saveCard: false,
    });
  };

  function handleCardChange(paymentMethod: any) {
    props.checkoutActions.updateSelectedPaymentMethod(paymentMethod);
    setState({
      ...state,
    });
  }

  function handleSaveCardChange() {
    setState({
      ...state,
      status: null,
      saveCard: !state.saveCard,
    });
  }

  async function addCreditCard() {
    setLoading(true);
    if (!stripe || !elements) {
      setState({
        ...state,
        status: "Stripe.js has not loaded yet.",
      });
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);
    const cardCvcElement = elements.getElement(CardCvcElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);

    const paymentMethodRes = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    console.log("paymentMethodRes", paymentMethodRes);

    if (paymentMethodRes.paymentMethod) {
      console.log("here");
    } else {
      setLoading(false);
    }

    const paymentMethodObj = {
      brand: paymentMethodRes.paymentMethod.card.brand,
      card_number_redacted: "•••• •••• •••• " + paymentMethodRes.paymentMethod.card.last4,
      stripe_payment_method_id: paymentMethodRes.paymentMethod.id,
    };

    // If the customer wants to save the card
    if (state.saveCard === true) {
      const intentRes = await GetPaymentMethodSetupIntent(false);
      if (intentRes.status !== 200 || !intentRes.data.data?.setup_intent?.client_secret) {
        setState({
          ...state,
          status: intentRes.data,
        });
        return;
      }

      const confirmSetupRes = await stripe.confirmCardSetup(intentRes.data.data.setup_intent.client_secret, {
        payment_method: {
          card: cardElement,
        },
      });

      if (confirmSetupRes.error) {
        setState({
          ...state,
          status: confirmSetupRes.error.message,
        });
        return;
      }

      console.log(confirmSetupRes);

      if (confirmSetupRes.setupIntent.status !== "succeeded") {
        setState({
          ...state,
          status: `confirm setup failed, ${confirmSetupRes.setupIntent.status}`,
        });
        return;
      }

      const res = await SavePaymentMethod(confirmSetupRes.setupIntent.payment_method, false);

      if (res.status !== 200) {
        setState({
          ...state,
          status: res.data,
        });
        return;
      }
    }

    // Set the payment method
    handleCardChange(paymentMethodObj);

    setLoading(false);
    // Close the modal
    closeModal();

    props.checkoutActions.fetchPaymentMethods();

    setState({
      ...state,
      saveCard: false,
    });
  }

  useEffect(() => {
    props.checkoutActions.fetchPaymentMethods();
    console.log(props.checkoutStore.selectedPaymentMethod);
  }, []);

  const [active, setActive] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const activator = (
    <div className="dropdown-toggle" onClick={toggleActive}>
      {props.checkoutStore.selectedPaymentMethod ? (
        <div className="credit-card">
          <div>
            <span className="method-brand">{props.checkoutStore.selectedPaymentMethod.brand.slice(0, 4)}</span>
            <span>{props.checkoutStore.selectedPaymentMethod.card_number_redacted}</span>
          </div>

          <span>
            <FontAwesomeIcon icon={["fas", "chevron-down"]} />
          </span>
        </div>
      ) : (
        <div className="placeholder">
          <span>Select a payment method</span>
          <span>
            <FontAwesomeIcon icon={["fas", "chevron-down"]} />
          </span>
        </div>
      )}
    </div>
  );

  function mapPaymentMethods() {
    const list: any = [];
    props.checkoutStore.paymentMethods.map((method: any, index: number) => {
      let icon;

      if (method.brand === "visa") {
        icon = <FontAwesomeIcon icon={["fab", "cc-visa"]} />;
      }

      if (method.brand === "mastercard") {
        icon = <FontAwesomeIcon icon={["fab", "cc-mastercard"]} />;
      }

      if (method.brand === "amex") {
        icon = <FontAwesomeIcon icon={["fab", "cc-amex"]} />;
      }

      list.push({
        icon,
        content: method.card_number_redacted,
        onAction: handleCardChange,
        value: method,
      });
    });

    return list;
  }

  const displayMethods =
    props.checkoutStore.paymentMethods.length > 0 || props.checkoutStore.selectedPaymentMethod !== null;

  const displayActionList = props.checkoutStore.paymentMethods.length > 0;

  const popoverRef = useRef(null);

  useOutsideAlerter(popoverRef, () => setActive(false));

  return (
    <>
      <h3>Payment Info</h3>
      {displayMethods ? (
        <div>
          <h5>Select Card</h5>
          <div className="popover-container">
            <Popover active={active} activator={activator} onClose={toggleActive}>
              {displayActionList && (
                <div ref={popoverRef}>
                  <ActionList items={mapPaymentMethods()} />
                </div>
              )}
            </Popover>
          </div>
        </div>
      ) : null}

      <button onClick={openModal} className="add-card-button">
        Add Card +
      </button>

      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles}>
        <button onClick={closeModal} className="exit" aria-label="Close Modal" title="Close modal">
          <FontAwesomeIcon icon={["fal", "times"]} />
        </button>
        <div className="modal-container">
          <h1>Add Card</h1>
          <div className="stripe-container">
            <span className="secondary-label">Card Number</span>
            <CardNumberElement
              className="card-input"
              id="card-element"
              options={CARD_OPTIONS}
              onChange={handleChange}
            />
            <div className="expiry-cvc-container">
              <div className="label-input-container">
                <span className="secondary-label">Expiry</span>
                <CardExpiryElement
                  className="expiry-input"
                  id="card-element"
                  options={CARD_OPTIONS}
                  onChange={handleChange}
                />
              </div>
              <div className="label-input-container">
                <span className="secondary-label">CVV</span>
                <CardCvcElement
                  className="cvc-input"
                  id="card-element"
                  options={CARD_OPTIONS}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="card-errors" role="alert">
              <p>{state.status}</p>
            </div>

            <label className="save-payment" htmlFor="saveCard">
              <input
                type="checkbox"
                name="saveCard"
                onChange={handleSaveCardChange}
                id="saveCard"
                checked={state.saveCard}
              />
              <span className="checkmark"></span>
              <span>Save payment for future purchases</span>
            </label>
          </div>
          <Button
            block
            shape="rounded"
            shadow
            type="primary"
            loading={loading}
            onClick={addCreditCard}
            className="add-payment-method-button"
          >
            Add Payment Method
          </Button>
        </div>
      </Modal>
    </>
  );
}
