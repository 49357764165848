import React, { useEffect, useState, useContext, createContext, Fragment } from "react";
import { useHistory } from "react-router-dom";

import { Button } from "../elements/button/index";
import { Switch } from "../elements/switch/index";
import { Radio } from "../elements/radio/index";
import { Title } from "../elements/typography/index";
import { Card } from "../elements/card/index";
import { Tag } from "../elements/tag/index";
import Modal from "react-modal";

import { CancelBooking, GetBooking } from "api/rpc";
import { StatusCode } from "api/protocols";

import Moment from "moment";

import "public/scss/myBookings.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IBookingInfo {
  facility: {
    long_name: string;
  };
  start_time: string;
  holes_booked: number;
  power_cart_quantity: number;
  confirmation: string;
  token: string;
  quantity: number;
}

export const CardGroupContext = createContext(undefined);

export default function MyBookings(props: any) {
  const history = useHistory();

  const [upcomingBookings, setUpcomingBookings] = useState<IBookingInfo[]>([]);
  const [pastBookings, setPastBookings] = useState<IBookingInfo[]>([]);

  useEffect(() => {
    void GetBookingObject();
  }, []);

  // modal settings
  const [modalIsOpen, setIsOpen] = useState<boolean>(false);

  Modal.setAppElement("#root");

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  async function GetBookingObject() {
    const res = await GetBooking("&extended=true&status=complete", true);

    if (res.status !== StatusCode.OK) {
      return;
    }

    const currentDate = Moment(new Date());

    // filters all upcoming tee time bookings by comparing current date
    setUpcomingBookings(res.data.data.filter((booking: any) => Moment(booking.start_time).isAfter(currentDate)));

    // filters all past tee time bookings by comparing current date
    setPastBookings(res.data.data.filter((booking: any) => Moment(booking.start_time).isSameOrBefore(currentDate)));
  }

  // Cannot read property 'cart_token' of null
  function navigateToConfirmation(confirmation: string) {
    history.push(`/confirmation/${confirmation}`);
  }

  const [tab, setTab] = useState();

  const handleRadioChange = (value: any) => {
    setTab(value);
  };

  const upcomingTab = tab === 1;
  const pastBookingsTab = tab === 2;

  const [loading, setLoading] = useState(false);

  const handleCancelBooking = async (booking: IBookingInfo) => {
    setLoading(true);

    const res = await CancelBooking(booking.token);

    if (res.status !== StatusCode.OK) {
      console.log("cancellation failed");
      setLoading(false);
    } else if (res.status === StatusCode.OK) {
      const updatedBookings = upcomingBookings.filter(upcomingBooking => {
        if (upcomingBooking.token !== booking.token) {
          if (upcomingBooking !== undefined) {
            return upcomingBooking;
          }
        }
      });

      setUpcomingBookings([...updatedBookings]);
      setLoading(false);
      closeModal();
    }
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      border: "none",
      borderRadius: "10px",
      maxWidth: "400px",
    },
    overlay: {
      backgroundColor: "rgba(0,0,0,.4)",
    },
  };

  return (
    <main className="bookings-main">
      <div className="wrapper">
        <section className="my-bookings-section">
          <div className="my-bookings-header-nav">
            <Title level={1} type={1} className="my-bookings-title">
              My Bookings
            </Title>

            <Radio.Group name="myBookings" className="my-bookings-group" onChange={handleRadioChange} defaultValue={1}>
              <Radio.Button value={1} name="myBookings">
                Upcoming
              </Radio.Button>
              <Radio.Button value={2} name="myBookings">
                Past Bookings
              </Radio.Button>
            </Radio.Group>
          </div>

          <h3>{upcomingTab ? "Upcoming" : "Past Bookings"}</h3>
          <div className="bookings-container">
            <Card.Group>
              {pastBookingsTab
                ? pastBookings.map((booking: IBookingInfo, index: number) => {
                    const buttons = (
                      <div className="my-bookings-button-container">
                        <Button onClick={() => navigateToConfirmation(booking.confirmation)}>Details</Button>
                      </div>
                    );

                    return (
                      <Card className="my-bookings-card" overlay={buttons} key={index} value={index}>
                        <div className="my-bookings-card-content">
                          <div>
                            <Title level={3} type={2} className="my-bookings-card-title">
                              {booking.facility.long_name}
                            </Title>

                            <div className="my-bookings-card-date">
                              <FontAwesomeIcon icon={["far", "calendar"]} />
                              {Moment(booking.start_time).format("dddd, MMMM Do YYYY")} at
                              <span>{Moment(booking.start_time).format("h:mm a")}</span>
                            </div>
                          </div>

                          <div className="my-bookings-card-content-tags">
                            <Tag icon={<FontAwesomeIcon icon={["far", "users"]} />}>
                              {booking.quantity} {booking.quantity > 1 ? "Golfers" : "Golfer"}
                            </Tag>

                            <Tag icon={<FontAwesomeIcon icon={["far", "flag"]} />}>{booking.holes_booked} Holes</Tag>

                            {booking.power_cart_quantity !== 0 ? (
                              <Tag icon={<FontAwesomeIcon icon={["far", "car-side"]} />}>
                                {booking.power_cart_quantity} {booking.power_cart_quantity > 1 ? "Carts" : "Cart"}
                              </Tag>
                            ) : (
                              <Tag icon={<FontAwesomeIcon icon={["far", "walking"]} />}>Walking</Tag>
                            )}
                          </div>
                        </div>
                      </Card>
                    );
                  })
                : upcomingBookings.map((booking: IBookingInfo, index: number) => {
                    const buttons = (
                      <div className="my-bookings-button-container">
                        <Button onClick={() => navigateToConfirmation(booking.confirmation)}>Details</Button>
                        <Button onClick={openModal}>Cancel Booking</Button>
                      </div>
                    );

                    return (
                      <Fragment key={index}>
                        <Card className="my-bookings-card" overlay={buttons} value={index}>
                          <div className="my-bookings-card-content">
                            <div>
                              <Title level={3} type={2} className="my-bookings-card-title">
                                {booking.facility.long_name}
                              </Title>

                              <div className="my-bookings-card-date">
                                <FontAwesomeIcon icon={["far", "calendar"]} />
                                {Moment(booking.start_time).format("dddd, MMMM Do YYYY")} at
                                <span>{Moment(booking.start_time).format("h:mm a")}</span>
                              </div>
                            </div>

                            <div className="my-bookings-card-content-tags">
                              <Tag icon={<FontAwesomeIcon icon={["far", "users"]} />}>
                                {booking.quantity} {booking.quantity > 1 ? "Golfers" : "Golfer"}
                              </Tag>

                              <Tag icon={<FontAwesomeIcon icon={["far", "flag"]} />}>{booking.holes_booked} Holes</Tag>

                              {booking.power_cart_quantity !== 0 ? (
                                <Tag icon={<FontAwesomeIcon icon={["far", "car-side"]} />}>
                                  {booking.power_cart_quantity} {booking.power_cart_quantity > 1 ? "Carts" : "Cart"}
                                </Tag>
                              ) : (
                                <Tag icon={<FontAwesomeIcon icon={["far", "walking"]} />}>Walking</Tag>
                              )}
                            </div>
                          </div>
                        </Card>

                        <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles}>
                          <h1 className="cancel-booking-title">Are you sure?</h1>
                          <div className="cancel-booking-button-group">
                            <Button onClick={closeModal} className="cancel-booking-button-group-cancel">
                              Close
                            </Button>
                            <Button
                              type="primary"
                              danger
                              onClick={() => handleCancelBooking(booking)}
                              block
                              loading={loading}
                            >
                              Cancel Booking
                            </Button>
                          </div>
                        </Modal>
                      </Fragment>
                    );
                  })}
            </Card.Group>
          </div>
        </section>
      </div>
    </main>
  );
}
