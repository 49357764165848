import React from "react";
import SweetAlert from "sweetalert2-react";

export interface IErrorboxProps {
  showError: boolean;
  errorMessage: string;
  uiActions: any;
}

export default function Errorbox(props: IErrorboxProps) {
  return (
    <div>
      <SweetAlert
        show={props.showError}
        title="Error"
        text={props.errorMessage}
        onConfirm={() => props.uiActions.closeError()}
      />
    </div>
  );
}
