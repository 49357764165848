import React from "react";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);

export default function StripeWrapper(props: any) {
  return <Elements stripe={stripePromise}>{props.children}</Elements>;
}
