import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputRange from "react-input-range";

import "react-input-range/lib/css/index.css";
import "public/scss/filterDate.scss";

interface IProps {
  title: string;
  onChange?: (value: any) => void;
  max: number;
  min: number;
  step: number;
  minValue: number;
  maxValue: number;
}

interface IDates {
  value: any;
}

export const FilterSlider: React.FC<IProps> = props => {
  const { title, max, min, step, minValue, maxValue, onChange } = props;

  const [dates, setDates] = useState<IDates>({
    value: {
      min: minValue,
      max: maxValue,
    },
  });

  function convertTime(time: any) {
    time = ("0" + time).slice(-2);
    time = time + ":00";
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time = time.slice(0, -3);
      time[5] = +time[0] < 12 ? "am" : "pm"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }

    return time.join(""); // return adjusted time or original string
  }

  function handleChange(value: any) {
    setDates({ value });
    // onClick(value);
  }

  function changeComplete() {
    // sets loading state to false
    // filterSelected();
    if (onChange) {
      onChange(dates);
    }
  }

  const [timeSlider, setTimeSlider] = useState<boolean>(false);

  const sliderContainer = useRef(null);

  useEffect(() => {
    sliderContainer.current.innerText === "Time" ? setTimeSlider(true) : setTimeSlider(false);
  });

  return (
    <div className="filter">
      <div className="filter-title">
        <p ref={sliderContainer}>{title}</p>
        <span className="max-min text-subdued">
          <p className="slider-text">
            {!timeSlider
              ? `${dates.value.min} - ${dates.value.max}`
              : `${convertTime(dates.value.min)} - ${convertTime(dates.value.max)}`}
          </p>
        </span>
      </div>
      <div className="date-filter-container">
        <InputRange
          draggableTrack
          step={step}
          maxValue={max}
          minValue={min}
          value={dates.value}
          onChange={handleChange}
          onChangeComplete={changeComplete}
        />
      </div>
    </div>
  );
};
