export interface ICheckoutState {
  name: string;
  price: number;
  paymentMethods: [];
  selectedPaymentMethod: string | null;
}

export interface ICheckoutAction {
  type: string;
  payload: any;
}
const initialState: ICheckoutState = {
  name: "",
  price: 0,
  paymentMethods: [],
  selectedPaymentMethod: null,
};
export default function CheckoutReducer(state = initialState, action: ICheckoutAction) {
  switch (action.type) {
    case "update.payment.methods":
      return {
        ...state,
        paymentMethods: action.payload,
      };
    case "update.selected.payment.method":
      return {
        ...state,
        selectedPaymentMethod: action.payload,
      };
    default:
      return state;
  }
}
