import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "public/scss/footer.scss";

export default function Footer() {
  return (
    <footer>
      <div className="wrapper">
        <div className="footer-content">
          <div className="logo-social-nav-container">
            <img src="public/images/clubhouse_logo_full.png" alt="Clubhouse Golf" />
          </div>
          <div className="footer-navs-container">
            <nav className="footer-nav-1">
              <ul>
                <li>Marketplace</li>
                <li>
                  <a href="#">Tee Times</a>
                </li>
                <li>
                  <a href="#">Daily Deals</a>
                </li>
                <li>
                  <a href="#">Flex Passes</a>
                </li>
                <li>
                  <a href="mailto:info@clubhousegolf.ca">Contact</a>
                </li>
              </ul>
            </nav>
            <nav className="footer-nav-2">
              <ul>
                <li>Legal</li>
                <li>
                  <a href="/privacy">Privacy Policy</a>
                </li>
                <li>
                  <a href="#">Terms Of Use</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <p className="copyright">Clubhouse Golf 2021</p>
      </div>
    </footer>
  );
}
