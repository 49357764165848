import { GetCart } from "api/rpc";
import { LocalStorage } from "api/localstorage";
import { IBookingState , bookingActionTypes as actionTypes } from "reducers/booking";
import { StatusCode } from "api/protocols";
import { camelCase } from "lodash";


export const updateHoles = (holes: number) => {
  return (dispatch: any) => {
    dispatch({
      type: actionTypes.UPDATE_HOLES,
      payload: holes,
    });
  };
};

export const updatePlayerQuantity = (playerQuantity: number) => {
  return (dispatch: any) => {
    dispatch({
      type: actionTypes.UPDATE_PLAYER_QUANTITY,
      payload: playerQuantity,
    });
  };
};

export const updateCartQuantity = (cartQuantity: number) => {
  return (dispatch: any) => {
    dispatch({
      type: actionTypes.UPDATE_CART_QUANTITY,
      payload: cartQuantity,
    });
  };
};

export const updateCart = (cart: boolean) => {
  return (dispatch: any) => {
    dispatch({
      type: actionTypes.UPDATE_CART,
      payload: cart,
    });
  };
};
