import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { GetCart, UserLogout } from "api/rpc";
import { LocalStorage } from "api/localstorage";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName } from "@fortawesome/pro-light-svg-icons";
import "public/scss/menu.scss";

import { Button } from "elements/button/index";

import classNames from "classnames";

interface ISitePage {
  pageName: string; // can also use as a text label (or even a Route name?)
  route: string;
  faIcon?: IconName;
  enable?: boolean;
}

// Main menu for top of page:
export default function MainMenu(props: any) {
  const history = useHistory();
  const Location = useLocation();
  // const [cartCount, setCartCount] = useState(0);

  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);

  const arOMenuItems: ISitePage[] = [
    {
      pageName: `Tee Times`,
      route: "", // empty string for base Route, else risk of passing "//" to history.push below
      faIcon: `golf-ball`,
    },
    {
      pageName: `How It Works`,
      route: "how-it-works",
      faIcon: `ticket-alt`,
    },
    {
      pageName: `Buy Coins`,
      route: "buy-coins",
      faIcon: `clipboard-list-check`,
    },
    // {
    //   pageName: `Bookings`,
    //   route: "bookings",
    //   faIcon: `clipboard-list-check`,
    // },
  ];

  // async function handleLogout() {
  //   await UserLogout(true);
  //   props.authActions.userLogout();
  //   history.replace("/auth");
  // }

  const arOMenuActions: ISitePage[] = [
    {
      pageName: `Login`,
      route: "/auth",
      faIcon: `sign-in-alt`,
    },
    {
      pageName: `Sign Up`,
      route: "/auth/register",
      faIcon: `sign-in-alt`,
    },
  ];

  function MapMainMenu(OMenuItem: ISitePage, isActionList?: boolean, index?: number) {
    //quick-n-dirty find current page/route; "pathname" ignores URL params too:
    const isCurrentPage: boolean = Location.pathname === "/" + OMenuItem.route;
    return (
      <li onClick={toggleNav} key={`menu-item-${OMenuItem.pageName.toLowerCase()}`}>
        <a
          // use an array instead of string concat:
          className={["menu-item", isActionList ? "menu-item-action" : ``, isCurrentPage ? "current-page" : ``]
            .filter(Boolean)
            .join(" ")} // manually concat into one string, space delims, filter out empty
          href={OMenuItem.route}
          // title={OMenuItem.pageName}

          /*  react-router-dom "Link" is another option instead of "a" tag, with fast-routing "to" attrib instead of href:
          to={
            OMenuItem.enable === false ? null : OMenuItem.route // explicit disabling
          }
          */
          onClick={OMenuItem.enable === false || isCurrentPage ? null : () => history.push("/" + OMenuItem.route)}
        >
          {OMenuItem.pageName}
        </a>
      </li>
    );
  }

  function toggleNav() {
    setMobileMenuOpen(!mobileMenuOpen);
  }

  const MainMenuList = arOMenuItems.map(OMenuItem => MapMainMenu(OMenuItem));
  const MainMenuAction = arOMenuActions.map(OMenuItem => MapMainMenu(OMenuItem, true));

  const handleLogout = async () => {
    await UserLogout(true);
    props.authActions.userLogout();
    history.replace("/auth");
  };

  const isCurrentPage: boolean = Location.pathname === "/bookings";

  const bookingClassName = classNames({ [`current-page`]: isCurrentPage }, `menu-item`);

  MainMenuList.push(
    <React.Fragment key="menu-item-booking">
      {props.authStore.user && (
        <li>
          <a href="/bookings" className={bookingClassName}>
            <span>Bookings</span>
          </a>
        </li>
      )}
    </React.Fragment>,
  );

  return (
    <>
      <nav className="mobile-menu" style={{ right: mobileMenuOpen ? "0%" : "100%" }}>
        <ul>
          <li>
            <button onClick={toggleNav}>
              <FontAwesomeIcon icon={["fal", "arrow-left"]} />
            </button>
          </li>
          {MainMenuList}
          {props.authStore.user ? (
            <div className="coins-initials-container">
              <li>
                <a href="/buy-coins">
                  <span>{props.authStore.user.points.balance}</span>
                  <FontAwesomeIcon icon={["fas", "coins"]} />
                </a>
              </li>
              <li>
                {/* <a href="/account">{props.authStore.user.initials}</a> */}
                <Button shape="rounded" onClick={handleLogout} type="primary" size="medium">
                  Log Out
                </Button>
              </li>
            </div>
          ) : (
            arOMenuActions.map(OMenuItem => MapMainMenu(OMenuItem, true))
          )}
          <li className="mobile-logo">
            <img className="main-logo" src="/public/images/clubhouse_logo_full.png" alt="Clubhouse Golf" />
          </li>
        </ul>
      </nav>
      <nav className="main-menu">
        <div className="wrapper">
          {/* mobile navigation */}
          {props.authStore.user ? (
            <ul className="ul-user-mobile">
              <li>
                <button onClick={toggleNav}>
                  <FontAwesomeIcon icon={["fal", "align-left"]} />
                </button>
              </li>
              <div className="coins-initials-container">
                <li>
                  <a href="">
                    <span>{props.authStore.user.points.balance}</span>
                    <FontAwesomeIcon icon={["fas", "coins"]} />
                  </a>
                </li>
                <li>
                  {/* <a href="/account">{props.authStore.user.initials}</a> */}
                  <Button shape="rounded" onClick={handleLogout} type="primary" size="medium">
                    Log Out
                  </Button>
                </li>
              </div>
            </ul>
          ) : (
            <ul className="ul-no-user-mobile">
              <li>
                <button onClick={toggleNav}>
                  <FontAwesomeIcon icon={["fal", "align-left"]} />
                </button>
              </li>
              <li>
                <a aria-label="Home" title="home" href="/">
                  <img className="main-logo" src="/public/images/clubhouse_logo_full.png" alt="Clubhouse Golf" />
                </a>
              </li>
            </ul>
          )}

          {/* desktop navigation */}
          {props.authStore.user ? (
            <ul className="ul-user-desktop">
              <li>
                <a className="logo-link" href="/">
                  <img className="main-logo" src="/public/images/clubhouse_logo_full.png" alt="Clubhouse Golf" />
                </a>
              </li>
              <ul className="main-ul">{MainMenuList}</ul>
              <ul className="coins-initials-container">
                <li>
                  <a href="">
                    <span>{props.authStore.user.points.balance}</span>
                    <FontAwesomeIcon icon={["fas", "coins"]} />
                  </a>
                </li>
                <li>
                  {/* <a href="/account">{props.authStore.user.initials}</a> */}
                  <Button shape="rounded" onClick={handleLogout} type="primary" size="medium">
                    Log Out
                  </Button>
                </li>
              </ul>
            </ul>
          ) : (
            <ul className="ul-no-user-desktop">
              <li>
                <a aria-label="Home" title="home" href="/" className="logo-link">
                  <img className="main-logo" src="/public/images/clubhouse_logo_full.png" />
                </a>
              </li>
              <ul className="main-ul">{MainMenuList}</ul>
              <ul className="menu-action-ul">{MainMenuAction}</ul>
            </ul>
          )}
        </div>
      </nav>
    </>
  );
}
