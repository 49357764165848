import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "public/scss/secondaryMenu.scss";

export default function SecondaryMenu(props: any) {
  return (
    <nav className="secondary-menu">
      <ul>
        <li>
          <a onClick={props.previousAction}>
            <FontAwesomeIcon icon={props.icon} />
          </a>
        </li>
        <li dangerouslySetInnerHTML={{ __html: props.title }}></li>
        <li>
          <a href="/wallet">
            <span>{props.balance}</span>
            <FontAwesomeIcon icon={["fas", "coins"]} />
          </a>
        </li>
        <li>
          <a href="/account">{props.initials}</a>
        </li>
      </ul>
    </nav>
  );
}
