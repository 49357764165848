import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "public/scss/wallet.scss";

export default function Wallet(props: any) {
  return (
    <main>
      <div className="wrapper">
        <h1>Wallet</h1>
        <p className="total-coins">
          <span>
            <span className="balance">{props.authStore.user?.points?.balance}</span>
            <FontAwesomeIcon icon={["fas", "coins"]} />
          </span>
          <span>Balance</span>
        </p>
        <section className="wallet-options">
          <a href="/buy-coins">
            <span>
              <FontAwesomeIcon icon={["fal", "coins"]} />
              Purchase Coins
            </span>{" "}
            <FontAwesomeIcon icon={["fal", "arrow-right"]} />
          </a>
          <a href="#">
            <span>
              <FontAwesomeIcon icon={["far", "gift"]} /> Redeem gift card
            </span>{" "}
            <FontAwesomeIcon icon={["fal", "arrow-right"]} />
          </a>
          <a href="#">
            <span>
              <FontAwesomeIcon icon={["fal", "exchange-alt"]} />
              Transfer coins
            </span>
            <FontAwesomeIcon icon={["fal", "arrow-right"]} />
          </a>
          <a href="#">
            <span>
              <FontAwesomeIcon icon={["far", "book"]} /> History
            </span>{" "}
            <FontAwesomeIcon icon={["fal", "arrow-right"]} />
          </a>
          <a href="#">
            <span>
              <FontAwesomeIcon icon={["fal", "question-circle"]} /> FAQ
            </span>{" "}
            <FontAwesomeIcon icon={["fal", "arrow-right"]} />
          </a>
        </section>
      </div>
    </main>
  );
}
